import { defineStore } from "pinia";
import _ from "lodash";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ApiResponseInterface, {
    LaravelPaginatedApiResourceInterface,
} from "../pages/interfaces/apiResponseInterface";
import EmployeeInterface from "../pages/interfaces/employeesInterface";
import ClientInterface from "../pages/interfaces/clientsInterface";
import { TeamScheduleInterface } from "../pages/interfaces/teamsInterface";

interface Teams {
    type: string;
    name: string;
    employees: { employee: EmployeeInterface }[];
    client: ClientInterface;
    leads: {
        type: string;
        employees: {
            type: string;
            employee: {
                name: string;
                type: string;
                position: string;
                personal_email: string;
                clockin_status_type_id: number;
            };
        }[];
    };
    members: {
        type: string;
        employees: {
            type: string;
            employee: {
                name: string;
                type: string;
                position: string;
                personal_email: string;
                clockin_status_type_id: number;
            };
        }[];
    };
}

interface Team {
    id: number;
    client_id: number;
    name: string;
    employee_teams: any[];
    team_point_of_contacts: any[];
}

// interface Team {
//     id: number;
//     client_id: number;
//     name: string;
//     employee_team: any[];
// }

export const useTeamStore = defineStore("TeamStore", {
    state: () => {
        return {
            teamsTableData: { status: "success", data: [], total: 0 },
            teams: [] as Team[],
            mode: "Add",
            myTeam: [],
            MyTeamClient: [] as unknown as ClientInterface,
            myTeams: [] as Teams[],
            teamSchedule:
                {} as LaravelPaginatedApiResourceInterface<TeamScheduleInterface>,
            $sentry: null,
        };
    },
    getters: {
        getMode(): string {
            return this.mode;
        },
    },
    actions: {
        async fill(params: any) {
            console.log("fill");
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/teams", {
                    params: params,
                });
                this.teamsTableData = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getAllTeams() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<[]>>("/get-all-teams");
                this.teams = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
        getTeam(id: number): Team {
            const team = _.find(this.teams, (team) => {
                return team.id === id;
            });

            if (typeof team === "undefined") {
                this.mode = "Add";
                return {
                    id: 0,
                    client_id: 0,
                    name: "",
                    employee_teams: [],
                    team_point_of_contacts: [],
                };
            } else {
                this.mode = "Update";
                return team;
            }
        },
        //TODO all store update methods should be renamed to save I think it makes more sense
        async save(formData: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/teams", formData);
                return { status: response.status, data: response.data };
            } catch (error: any) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                return { status: "error", data: error?.response?.data?.errors };
            }
        },
        async deleteTeamMember(id: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<any>>("/delete-team-member", {
                    data: { id: id },
                });
                console.log(response);
                console.log("Team Deleted Success");
                return true;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                return false;
            }
        },
        async delete(id: any) {
            let status = "";
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<any>>("/teams", { id: id });
                console.log(response);
                console.log("Team Deleted Success");
                status = "success";
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                status = "error";
            }

            return {
                status: status,
            };
        },
        async getMyTeam() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-my-team");
                this.myTeam = response.data;
                return response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getUserTeam(id: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/get-user-team", { id: id });
                this.myTeams = response.data.teams;
                this.MyTeamClient = response.data.client;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },

        async getTeamSchedule(payload: any) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const response = await new Http(baseUrl).get<
                LaravelPaginatedApiResourceInterface<TeamScheduleInterface>
            >("/get-team-schedule?" + payload);

            this.teamSchedule = response;
        },

        filterTeamEmployeesByName(keyword = "", ctr: number) {
            const leads = this.myTeams[ctr].leads.employees;
            const members = this.myTeams[ctr].members.employees;
            const allEmployees = leads.concat(members);

            if (keyword === "") {
                return allEmployees;
            }

            return allEmployees.filter(
                (obj: { employee: { name: string } }) => {
                    return obj.employee.name
                        .toLowerCase()
                        .includes(keyword.toLowerCase());
                }
            );
        },
        filterTeamsCountByName(keyword = "") {
            if (keyword === "") {
                return true;
            }
            let res = false;
            if (keyword != "") {
                _.forEach(this.myTeams, (obj) => {
                    if (
                        obj.employees.some((employee) =>
                            employee.employee.name
                                .toLowerCase()
                                .includes(keyword.toLowerCase())
                        )
                    ) {
                        res = true;
                    }
                });
                return res;
            }
        },
    },
});
