<script setup lang="ts">
import { ref, provide, onMounted } from "vue";
import { usePipedriveStore } from "../../../stores/pipedriveStore";
import { getColor } from "../../../utils/colors";
import ChartPendingEngagement from "../charts/ChartPendingEngagement.vue";

const PipedriveStore = usePipedriveStore();

const loading = ref(true);

const chartColors = [
    getColor("pending", 0.9),
    getColor("warning", 0.9),
    getColor("danger", 0.9),
    getColor("primary", 0.9),
    getColor("secondary", 0.9),
    getColor("success", 0.9),
    getColor("info", 0.9),
    getColor("dark", 0.9),
    "#881337",
    "#701a75",
    "#701a75",
    "#581c87",
    "#4c1d95",
    "#312e81",
    "#0c4a6e",
];

onMounted(async () => {
    await PipedriveStore.fillPendingEngagements().then((response) => {
        loading.value = false;
    });
});
</script>
<template>
    <div class="intro-y flex items-center h-10">
        <h2 class="text-lg font-medium truncate mr-5">Pending Engagements</h2>
    </div>
    <div class="intro-y box p-5 mt-5">
        <div class="mt-4" v-if="!loading">
            <ChartPendingEngagement
                :height="150"
                :pendingEngagements="PipedriveStore.pendingEngagements"
            />
        </div>
        <div class="w-52 sm:w-auto mx-auto mt-16">
            <template
                v-for="(
                    engagement, key, index
                ) in PipedriveStore.pendingEngagements"
            >
                <div class="flex items-center">
                    <div
                        class="w-2 h-2 rounded-full mr-3"
                        :style="{ 'background-color': chartColors[key] }"
                    ></div>
                    <span class="truncate">{{ engagement.name }}</span>
                    <span class="font-medium ml-auto">{{
                        engagement.total
                    }}</span>
                </div>
            </template>
        </div>
    </div>
</template>
