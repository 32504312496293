import { defineStore } from "pinia";
import {
    PayrollConfigurationInterface,
    PayrollConfigurationPaginatedInterface,
} from "../pages/interfaces/payrollConfigurationInterface";
import Http from "../services/Http";

interface ApiResponse<T> {
    data: T;
    status: T;
}

export const usePayrollConfigurationStore = defineStore(
    "PayrollConfigurationStore",
    {
        state: (): {
            payrollConfigurations: PayrollConfigurationPaginatedInterface;
            payrollConfiguration: PayrollConfigurationInterface;
        } => {
            return {
                payrollConfigurations:
                    {} as PayrollConfigurationPaginatedInterface,
                payrollConfiguration: {} as PayrollConfigurationInterface,
            };
        },

        actions: {
            async getPayrollConfigurations(params: any): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponse<PayrollConfigurationPaginatedInterface>
                >("/payroll-configurations", { params });

                this.payrollConfigurations = data;
            },

            async getPayrollConfiguration(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const data = await new Http(
                    baseUrl
                ).get<PayrollConfigurationInterface>(
                    `/payroll-configurations/${id}`
                );

                this.payrollConfiguration = data;
            },

            async savePayrollConfiguration(
                payload: PayrollConfigurationInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const data = await new Http(
                    baseUrl
                ).post<PayrollConfigurationInterface>(
                    "/payroll-configurations",
                    payload
                );

                this.payrollConfigurations.data.push(data);
            },

            async updatePayrollConfiguration(
                payload: PayrollConfigurationInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const data = await new Http(
                    baseUrl
                ).put<PayrollConfigurationInterface>(
                    `/payroll-configurations/${payload.id}`,
                    payload
                );

                const index = this.payrollConfigurations.data.findIndex(
                    (item) => item.id === data.id
                );

                this.payrollConfigurations.data[index] = data;
            },

            async deletePayrollConfiguration(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(`/payroll-configurations/${id}`);

                const index = this.payrollConfigurations.data.findIndex(
                    (item) => item.id === id
                );

                this.payrollConfigurations.data.splice(index, 1);
            },

            async getCurrentPayrollConfiguration(params: any): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const data = await new Http(
                    baseUrl
                ).get<PayrollConfigurationInterface>(
                    `/payroll-configurations`,
                    {
                        params,
                    }
                );

                this.payrollConfiguration = data;
            },
        },
    }
);
