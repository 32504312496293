import { defineStore } from "pinia";
import { EmployeeNoteInterface } from "../pages/interfaces/employeeNoteInterface";
import Http from "../services/Http";

interface ApiResponseInterface<T> {
    data: T;
    success: boolean;
}
export const useEmployeeNoteStore = defineStore("EmployeeNoteStore", {
    state: (): {
        notes: EmployeeNoteInterface[];
        note: EmployeeNoteInterface;
    } => {
        return {
            notes: [],
            note: {} as EmployeeNoteInterface,
        };
    },
    actions: {
        async getEmployeeNotes(employee_id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            let url = "/employee-notes";

            if (employee_id) {
                url += `?employee_id=${employee_id}`;
            }

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeNoteInterface[]>
            >(url);

            this.notes = data;
        },
        async addNote(payload: EmployeeNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<EmployeeNoteInterface>
            >("/employee-notes", payload);

            this.notes.push(data);
        },
        async updateNote(payload: EmployeeNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<EmployeeNoteInterface>
            >(`/employee-notes/${payload.id}`, payload);

            const index = this.notes.findIndex(
                (a: EmployeeNoteInterface) => a.id === data.id
            );

            Object.assign(this.notes[index], data);
        },
        async deleteNote(id: number) {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete<void>(`/employee-notes/${id}`);

            const index = this.notes.findIndex(
                (a: EmployeeNoteInterface) => a.id === id
            );
            this.notes.splice(index, 1);
        },
    },
});
