<template>
    <div class="p-5 mt-5 box intro-y">
        <div class="flex items-center justify-between">
            <button @click="prevMonth">
                <Lucide class="w-5 h-5 text-slate-500" icon="ChevronLeft" />
            </button>
            <div class="mx-auto text-base font-medium">
                {{ monthNames[currentMonth] }}
            </div>
            <button @click="nextMonth">
                <Lucide class="w-5 h-5 text-slate-500" icon="ChevronRight" />
            </button>
        </div>
        <div class="grid grid-cols-7 gap-4 mt-5 text-center">
            <div class="font-medium">Su</div>
            <div class="font-medium">Mo</div>
            <div class="font-medium">Tu</div>
            <div class="font-medium">We</div>
            <div class="font-medium">Th</div>
            <div class="font-medium">Fr</div>
            <div class="font-medium">Sa</div>
            <template v-for="day in daysInMonth">
                <div
                    :class="{
                        'text-slate-500': isDifferentMonth(day),
                        'bg-gray-200': isToday(day),
                    }"
                    class="py-0.5 rounded relative"
                >
                    {{ day }}
                </div>
            </template>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import Lucide from "../../../base-components/Lucide";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonthIndex = currentDate.getMonth();

const currentMonth = ref(new Date().getMonth()); // current month index (0-11)
const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

// Returns the number of days in the specified month and year
function getDaysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
}

const startingDay = new Date(currentYear, currentMonth.value, 1).getDay();

const daysInMonth = Array.from(
    { length: getDaysInMonth(currentMonthIndex, currentYear) },
    (_, i) => i + 1
);

// Returns true if the specified day is not in the current month
function isDifferentMonth(day: number): boolean {
    const date = new Date(currentYear, currentMonth.value, day);
    return date.getDay() !== (day + startingDay) % 7;
}

// Returns true if the specified day is today's date
function isToday(day: number): boolean {
    return day === currentDate.getDate() && !isDifferentMonth(day);
}

const prevMonth = () => {
    currentMonth.value = (currentMonth.value - 1 + 12) % 12;
};

// function to navigate to next month
const nextMonth = () => {
    currentMonth.value = (currentMonth.value + 1) % 12;
};
</script>
