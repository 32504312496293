<script lang="ts" setup>
import { defineEmits, defineProps, reactive, ref, toRefs, watch } from "vue";
import { useClientsStore } from "../../stores/clientsStore";
import { useHelperStore } from "../../stores/helperStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from "vue-router";
import _ from "lodash";
import Button from "../../base-components/Button";
import TomSelect from "../../base-components/TomSelect";
import { FormLabel } from "../../base-components/Form";
import ClientInterface from "../interfaces/clientsInterface";

const route = useRoute();
const id = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

const ClientsStore = useClientsStore();
const HelperStore = useHelperStore();

const props = defineProps({
    data: Object,
    id: Number,
});
const client_id = ref();

watch(
    () => props.id,
    async (newValue, oldValue) => {
        console.log("watching team" + newValue);
        console.log("watching team" + oldValue);

        if (newValue !== 0) {
            console.log("id", newValue);
            client_id.value = newValue;
        }
    }
);

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const uploadFile = (e: Event) => {
    const target = e.target as HTMLInputElement;
    if (!target.files) {
        return;
    }
    const files = Array.from(target.files);
    attachments.data.push(files);
};

const form = reactive({
    type_id: "",
    client_id: parseInt(id),
    attachments: [] as File[][],
});

const rules = {
    type_id: { required },
    attachments: { required },
};

const formReset = () => {
    form.type_id = "";
};

const validate = useVuelidate(rules, toRefs(form));
const sendFiles = async () => {
    const formData = new FormData();
    formData.append("type_id", form.type_id);
    formData.append("client_id", String(form.client_id));
    console.log(attachments);
    if (attachments.data !== null) {
        _.forEach(attachments.data as File[][], function (files) {
            _.forEach(files, function (file) {
                console.log("File");
                console.log(file);
                formData.append("attachments[]", file);
            });
        });
        form.attachments = attachments.data;
        //console.log('all attachments', attachments);
    }
    console.log("preps");

    validate.value.$touch();
    if (validate.value.$invalid) {
        console.log("invalid toast");
    } else {
        await ClientsStore.uploadAttachment(
            formData as unknown as ClientInterface
        ).then((response) => {
            if (response.status === "success") {
                console.log("success toast");
                emit("status", true);
                emit("close", true);
            }
        });
    }
};

const emit = defineEmits(["status", "close"]);
const closeModal = () => {
    emit("status", true);
    emit("close", true);
};
</script>
<template>
    <div>
        <div class="intro-y pt-5 pb-5 pl-5 pr-2">
            <div
                class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg text-left"
            >
                <div
                    class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                >
                    <h2 class="font-medium text-base mr-auto mb-4">
                        Select File
                    </h2>
                </div>
                <div class="mt-3">
                    <FormLabel class="form-label"
                        >Attachment Type:
                        <span style="color: rgb(255, 0, 0)">*</span></FormLabel
                    >
                    <TomSelect
                        id="type_id"
                        v-model="form.type_id"
                        class="p-0"
                        name="type_id"
                    >
                        <option>Select Attachment Type</option>
                        <option
                            v-for="types of _.filter(
                                HelperStore.attachmentTypes,
                                (types) => types.type === 'client'
                            )"
                            :value="types.id"
                        >
                            {{ types.name }}
                        </option>
                    </TomSelect>
                    <template v-if="validate.type_id.$error">
                        <div
                            v-for="(error, index) in validate.type_id.$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
                <div class="mt-4">
                    <FormLabel class="form-label"
                        >Upload File:
                        <span style="color: rgb(255, 0, 0)">*</span></FormLabel
                    >
                    <p>
                        <input
                            id="uploadFileBtn"
                            :change="validate.attachments.$model"
                            class="btn shadow-md mr-2"
                            multiple
                            type="file"
                            @change="uploadFile"
                        />
                    </p>
                    <template v-if="validate.attachments.$error">
                        <div
                            v-for="(error, index) in validate.attachments
                                .$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-32 mb-2 mr-1"
                    variant="customPrimary"
                    @click="sendFiles"
                >
                    Upload
                </Button>
            </div>
        </div>
    </div>

    <!-- BEGIN: Success Notification Content -->
    <div
        id="upload-success-notification-content"
        class="toastify-content hidden flex"
    >
        <!--        <CheckCircleIcon class="text-success" />-->
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Attachment was saved successfully
            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
        id="upload-failed-notification-content"
        class="toastify-content hidden flex"
    >
        <!--        <XCircleIcon class="text-danger" />-->
        <div class="ml-4 mr-4">
            <div class="font-medium">Error occured!</div>
            <div class="text-slate-500 mt-1">
                Attachment was not saved successfully. Please try again.
            </div>
        </div>
    </div>
</template>
