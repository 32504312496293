import {defineStore} from "pinia";
import * as Sentry from "@sentry/browser";

interface GlobalStoreState {
    clockInSlider: boolean;
    $sentry: typeof Sentry | null;
}

export const useGlobalStore = defineStore("GlobalStore", {
    state: (): GlobalStoreState => {
        return {
            clockInSlider: false,
            $sentry: null,
        };
    },
    getters: {
        getClockInSlider(): boolean {
            return this.clockInSlider;
        }
    },
    actions: {
        setClockInSlider(value: boolean): void {
            this.clockInSlider = value;
        },
        closeClockInSlider(): void {
            this.clockInSlider = false;
        }
    }
});
