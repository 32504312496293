<script lang="ts" setup>
import { defineEmits, defineProps, reactive } from "vue";
import { useRolesAndPermissionStore } from "../../stores/rolesAndPermissionsStore";
import { FormSwitch } from "../../base-components/Form";

const RolesAndPermissionStore = useRolesAndPermissionStore();

interface Permission {
    activatedToRole: string[];
    friendlyName: string;
    id: number;
    name: string;
    permission_group_id: number;
    permission_id: number;
}

const props = defineProps({
    panelTitle: {
        type: String,
        required: true,
    },
    data: {
        type: Array as () => Permission[],
        default: [] as Permission[],
        required: true,
    },
    role: {
        type: String,
        required: true,
    },
    roleId: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(["response"]);

const formData = reactive({
    role_id: 0,
    role: "",
    permission_id: "",
});

const updateRole = (event: any, permission_id: string) => {
    formData.role_id = props.roleId;
    formData.role = props.role;
    formData.permission_id = permission_id;

    if (event.target.checked) {
        RolesAndPermissionStore.insertPermissionToRole(formData);
    } else {
        RolesAndPermissionStore.deletePermissionFromRole(formData);
    }
    emit("response", true);
};
</script>

<template>
    <div class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg">
        <div
            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
        >
            <h2 class="font-medium text-base mr-auto mb-4">{{ panelTitle }}</h2>
        </div>
        <div class="mt-3">
            <div class="mt-4">
                <div v-for="permission in data" class="mt-2">
                    <div class="mt-4 flex flex-row align-center items-center">
                        <FormSwitch>
                            <FormSwitch.Input
                                type="checkbox"
                                v-bind:checked="
                                    Object.values(
                                        permission.activatedToRole
                                    ).includes(role)
                                "
                                @change="
                                    updateRole(
                                        $event,
                                        permission.permission_id.toString()
                                    )
                                "
                            />
                        </FormSwitch>

                        <label class="ml-2">{{
                            permission.friendlyName
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
