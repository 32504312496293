<script lang="ts" setup>
import {defineProps, onMounted, reactive} from "vue";
import {useForgeBuildStore} from "../../stores/forgeBuildStore";
import Alert from "../../base-components/Alert";
import Lucide from "../../base-components/Lucide";
import Button from "../../base-components/Button";

const props = defineProps({
    location: {
        type: String,
        default: "private",
    },
});

const ForgeBuildStore = useForgeBuildStore();

const uiData = reactive({
    showAlert: false,
});

const refresh = () => {
    window.location.reload();
};

onMounted(async () => {
    await ForgeBuildStore.getLatestBuild();

    if (localStorage.getItem("forge-build") === null || localStorage.getItem("forge-build") === "") {
        localStorage.setItem("forge-build", ForgeBuildStore.build?.commit_hash ?? "");
    } else {
        const oldHash = localStorage.getItem("forge-build");
        if (oldHash !== ForgeBuildStore.build?.commit_hash) {
            uiData.showAlert = true;
            localStorage.setItem("forge-build", ForgeBuildStore.build?.commit_hash ?? "");
        }
    }
});
</script>
<template>
    <div v-if="props.location === 'private'">
        <div v-if="uiData.showAlert" class="m-4 shadow-lg">
            <Alert v-slot="{ dismiss }" class="flex items-center mb-2" variant="warning">
                <Lucide class="w-6 h-6 mr-2" icon="AlertCircle"/>
                A new system update has been released. Please refresh your browser to get the latest version.&nbsp;&nbsp;
                <a class="flex-none rounded-full bg-custom-color-800 py-1 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                   href="#"
                   @click="refresh"
                >Refresh Now</a>
                <!--                <Alert.DismissButton aria-label="Close" class="text-white" type="button" @click="dismiss">-->
                <!--                    <Lucide class="w-4 h-4" icon="X"/>-->
                <!--                </Alert.DismissButton>-->
            </Alert>
        </div>
    </div>
    <div v-else>
        <div v-if="uiData.showAlert"
             class="relative isolate flex items-center gap-x-6 overflow-hidden bg-warning py-2.5 px-6 sm:px-3.5 sm:before:flex-1 shadow-lg"
             style="z-index: 999"
        >
            <svg
                aria-hidden="true"
                class="absolute top-1/2 left-[max(45rem,calc(50%+8rem))] -z-10 w-[36.0625rem] -translate-y-1/2 transform-gpu blur-2xl"
                viewBox="0 0 577 310"
            >
                <use href="#1d77c128-3ec1-4660-a7f6-26c7006705ad"/>
            </svg>
            <div
                class="flex flex-wrap items-center gap-y-2 gap-x-4 animate-pulse"
            >
                <p class="text-sm leading-6 text-gray-900">
                    <strong class="font-semibold">System Notification!</strong>
                    <svg
                        aria-hidden="true"
                        class="mx-2 inline h-0.5 w-0.5 fill-current"
                        viewBox="0 0 2 2"
                    >
                        <circle cx="1" cy="1" r="1"/>
                    </svg>
                    A new system update has been released. Please refresh your
                    browser to get the latest version.
                </p>
                <a class="flex-none rounded-full bg-custom-color-800 py-1 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                   href="#"
                   @click="refresh"
                >Refresh Now</a
                >
            </div>
            <div class="flex flex-1 justify-end">
                <button
                    class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                    type="button"
                >
                    <span class="sr-only">Dismiss</span>
                    <svg
                        aria-hidden="true"
                        class="h-5 w-5 text-gray-900"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
