import { defineStore } from "pinia";
import EmployeeAttendancesInterface from "../pages/interfaces/employeeAttendancesInterface";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import {
    ShiftBreakInterface,
    ShiftInterface,
} from "../pages/interfaces/shiftInterface";

interface ClockInsStoreState {
    myAttendance: EmployeeAttendancesInterface & {
        shift: ShiftInterface;
        breaks: ShiftBreakInterface[];
    }; //
    clockInsToday: 0;
    clockOutToday: 0;
    noClockInsToday: 0;
    noClockOutsToday: 0;
    onBreakToday: 0;
    lates: 0;
    enableClockIn: boolean;
    enableClockInError: string;
    scheduledEmployeesToday: 0;
    breaks: any[];
    $sentry: typeof Sentry | null;
}

export const useClockInsStore = defineStore("ClockInsStore", {
    state: (): ClockInsStoreState => {
        return {
            myAttendance: {
                id: 0,
                employee_id: 0,
                ip_address: "",
                shift_id: 0,
                start_time: null,
                end_time: null,
                total_hours: 0,
                created_at: null,
                updated_at: null,
                shift: {} as ShiftInterface,
                breaks: [],
            },
            clockInsToday: 0,
            clockOutToday: 0,
            noClockInsToday: 0,
            noClockOutsToday: 0,
            onBreakToday: 0,
            lates: 0,
            scheduledEmployeesToday: 0,
            breaks: [],
            $sentry: null,
            enableClockIn: false,
            enableClockInError: "",
        };
    },
    actions: {
        async verifyIpAddress(params: any): Promise<void> {
            try {
                var screenWidth = window.screen.width;
                var screenHeight = window.screen.height;

                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/verify-ip-address", params);
                this.enableClockIn = true;
                this.enableClockInError = "";
            } catch (error) {
                //@ts-ignore
                if (error.response && error.response.data) {
                    //@ts-ignore
                    this.enableClockInError = error.response.data.data;
                } else {
                    console.error(
                        "Error response or data is undefined:",
                        error
                    );
                    this.enableClockInError =
                        "Error verifying IP address. Please contact your IT administrator.";
                }
                this.enableClockIn = false;
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalClockInsForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-clockins-for-today"
                );
                this.clockInsToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalClockOutForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-clockout-for-today"
                );
                this.clockOutToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalNoClockInsForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-no-clockins-for-today"
                );
                this.noClockInsToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalNoClockoutsForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-no-clockouts-for-today"
                );
                this.noClockOutsToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalOnBreakForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-on-break-for-today"
                );
                this.onBreakToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getTotalLates(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-total-lates");
                this.lates = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getScheduledEmployeesForToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-total-scheduled-employees-for-today"
                );
                this.scheduledEmployeesToday = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async getMyShiftDetailsToday(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-my-shift-details-today");
                if (Object.keys(response.data).length !== 0) {
                    // @ts-ignore TODO: Fix this
                    this.myAttendance = response.data;
                }
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
            }
        },
        async startShift(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/start-shift");

                this.myAttendance = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async endShift(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/end-shift", {
                    id: this.myAttendance.id,
                });
                this.myAttendance = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async startBreak(id: number): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/start-break", {
                    employee_attendance_id: this.myAttendance.id,
                    shift_break_id: id,
                });
                this.myAttendance = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async endBreak(): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>("/end-break", {
                    employee_attendance_id: this.myAttendance.id,
                });
                this.myAttendance = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
    },
});
