<script lang="ts" setup>
import CustomIcon from "../../Icons/CustomIcon.vue";
import { onMounted } from "vue";
import { useClockInsStore } from "../../../stores/clockInsStore";

const ClockInsStore = useClockInsStore();

onMounted(async () => {
    await ClockInsStore.getScheduledEmployeesForToday();
});
</script>
<template>
    <div class="report-box zoom-in">
        <div class="box p-5">
            <div class="flex">
                <CustomIcon :height="28" :icon="'Calendar'" :width="28" />

                <div class="ml-auto"></div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">
                {{ ClockInsStore.scheduledEmployeesToday }}
            </div>
            <div class="text-md text-slate-500 mt-1">
                Scheduled Employees Today
            </div>
        </div>
    </div>
</template>
