<script lang="ts" setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { Slideover } from "../base-components/Headless";
import Lucide from "../base-components/Lucide";
import Button from "../base-components/Button";

type Size = "sm" | "md" | "lg" | "xl";

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
    header: {
        type: String,
        default: "Client Form Details",
    },
    size: {
        type: String as () => Size | undefined,
        default: "xl",
    },
});

const size = ref<Size | undefined>(props.size as Size | undefined);

const emit = defineEmits(["close"]);

const openSlider = ref(false);

const closeSlider = (event: any) => {
    openSlider.value = false;
    emit("close", true);
};

watch(
    () => props.isOpen,
    (value) => {
        openSlider.value = value;
        size.value = props.size;
    }
);

watch(
    () => props.size,
    (value) => {
        size.value = value;
    }
);

onMounted(() => {
    size.value = props.size as Size | undefined;
});
</script>

<template>
    <!-- @ts-ignore -->
    <Slideover
        :open="openSlider"
        :size="size"
        @close="
            () => {
                closeSlider(false);
            }
        "
    >
        <Slideover.Panel>
            <Slideover.Title class="p-5 flex">
                <h2 class="mr-auto text-base font-medium">{{ header }}</h2>
                <Button
                    class=""
                    variant="outline-secondary"
                    @click="
                        (event) => {
                            event.preventDefault();
                            closeSlider(false);
                        }
                    "
                >
                    <Lucide class="w-4 h-4" icon="X" />
                </Button>
            </Slideover.Title>
            <Slideover.Description class="p-1 text-center">
                <slot></slot>
            </Slideover.Description>
        </Slideover.Panel>
    </Slideover>
</template>
