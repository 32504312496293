<script setup lang="ts">
import _ from "lodash";
import moment from "moment/moment";
import { useEmployeesStore } from "../../../stores/employeesStore";
import CustomIcon from "../../Icons/CustomIcon.vue";
import Lucide from "../../../base-components/Lucide";
import Button from "../../../base-components/Button";
import { useAuthStore } from "../../../stores/authStore";
import { PropType, ref, defineEmits } from "vue";
import EmployeeInterface from "../../interfaces/employeesInterface";
import FormSlider from "../../FormSlider.vue";
import FormUploadEmployee from "../../forms/FormUploadEmployee.vue";
import { useRoute } from "vue-router";
import CardSkeleton from "../CardSkeleton.vue";
import ConfirmModal from "../ConfirmModal.vue";

const { employee, loading } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        default: () => ({}),
    },
    loading: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(["status"]);
const route = useRoute();

const EmployeesStore = useEmployeesStore();
const AuthStore = useAuthStore();

enum AttachmentTypes {
    MedicalExam = 31,
    BIR = 40,
    TIN = 41,
    SSS = 42,
    PagIbig = 43,
    PhilHealth = 44,
}

const id = ref<string | string[]>(
    Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);
const slider = ref(false);
const dialog = ref(false);
const attachmentId = ref<number | null>(null);

const close = () => {
    slider.value = false;
};

const open = () => {
    slider.value = true;
};

const initials = (name: any) => {
    if (typeof name === "undefined") {
        return "";
    }
    let fullName = name;
    let split = fullName.split(" ");
    let firstInitial = split[0].charAt(0).toUpperCase();
    let secondInitial = split[split.length - 1].charAt(0).toUpperCase();
    return firstInitial + secondInitial;
};

const downloadAttachment = async (ticket_id: any, file_name: any) => {
    await EmployeesStore.downloadAttachment({
        reference_id: ticket_id,
        file_name: file_name,
    });
};

const onClickDeleteAttachment = (id: number) => {
    dialog.value = !dialog.value;

    attachmentId.value = id;
};

const onClickConfirm = async (event: any) => {
    if (event === null) {
        return;
    }

    if (event === false) {
        attachmentId.value = null;
        return;
    }

    if (attachmentId.value === null) {
        return;
    }

    await EmployeesStore.deleteAttachment(attachmentId.value);
    attachmentId.value = null;
    emit("status", true);
};
</script>

<template>
    <CardSkeleton v-if="loading" />

    <template v-else>
        <div
            v-if="AuthStore.hasPermission('can view employee attachments')"
            class="intro-y box mt-5 lg:mt-0 mb-5"
        >
            <div class="relative flex items-center p-5">
                <h2 class="font-medium text-base mr-auto">Recent Files</h2>

                <Button
                    v-if="
                        AuthStore.hasPermission('can add employee attachments')
                    "
                    class="text-xs"
                    variant="soft-secondary"
                    @click="open"
                >
                    Upload a file
                </Button>
            </div>

            <div
                v-if="employee && _.isArray(employee?.attachments)"
                class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
            >
                <ul
                    v-for="attachment of employee?.attachments"
                    class="border border-gray-200 rounded-md divide-y divide-gray-200 mb-2 shadow"
                    role="list"
                >
                    <li
                        class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                        <div class="w-0 flex-1 flex items-center">
                            <CustomIcon
                                :height="20"
                                :icon="'Paperclip'"
                                :width="20"
                            />

                            <span
                                v-if="
                                    Object.values(AttachmentTypes).includes(
                                        attachment.attachment_type_id
                                    )
                                "
                                class="ml-2"
                            >
                                <Lucide
                                    :height="20"
                                    :width="20"
                                    class="text-yellow-500"
                                    icon="Star"
                                />
                            </span>

                            <span class="ml-2 flex-1 w-0 truncate">
                                {{ attachment.attachment_type?.name }} -
                                {{
                                    moment(attachment.created_at).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                                <span v-if="attachment.user">
                                    {{ initials(attachment.user.name) }}
                                </span>
                            </span>
                        </div>
                        <div class="ml-4 flex gap-x-2 flex-shrink-0">
                            <a
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete employee attachments'
                                    )
                                "
                                class="font-medium text-custom-color-800 hover:text-indigo-500"
                                href="#"
                                @click="
                                    downloadAttachment(
                                        attachment.employee_id,
                                        attachment.download_name
                                    )
                                "
                            >
                                <CustomIcon
                                    :height="20"
                                    :icon="'Download'"
                                    :width="20"
                                />
                            </a>
                            <div>
                                <a
                                    v-if="
                                        AuthStore.hasPermission(
                                            'can download employee attachments'
                                        )
                                    "
                                    class="font-medium text-red-500 hover:text-red-800"
                                    href="#"
                                    @click="
                                        onClickDeleteAttachment(attachment.id)
                                    "
                                >
                                    <Lucide class="w-5 h-5" icon="Trash" />
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>

                <div
                    v-if="employee?.attachments.length === 0"
                    class="px-4 py-6 sm:px-6"
                >
                    No attachments yet.
                </div>
            </div>
        </div>
    </template>

    <FormSlider
        header="Upload Employee Attachments"
        :isOpen="slider"
        @close="close"
    >
        <FormUploadEmployee
            :id="Number(id)"
            @close="close"
            @status="emit('status', true)"
        />
    </FormSlider>

    <ConfirmModal
        :displayConfirmModal="dialog"
        second-description="File has been deleted successfully."
        @closeConfirmModal="dialog = false"
        @proceedProcess="onClickConfirm($event)"
    />
</template>

<style scoped></style>
