import { defineStore } from "pinia";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import {
    EmployeeAttendacePaginatedInterface,
    EmployeeAttendanceInterface,
} from "../pages/interfaces/employeeAttendanceInterface";
import { useAuthStore } from "./authStore";
import moment from "moment";
import { EmployeeScheduleInterface } from "../pages/interfaces/employeeScheduleInterface";

export const useEmployeeAttendanceStore = defineStore(
    "EmployeeAttendanceStore",
    {
        state: (): {
            attendances: EmployeeAttendacePaginatedInterface;
            schedules: EmployeeScheduleInterface[];
            schedule: EmployeeScheduleInterface;
        } => {
            return {
                attendances: {} as EmployeeAttendacePaginatedInterface,
                schedules: [] as EmployeeScheduleInterface[],
                schedule: {} as EmployeeScheduleInterface,
            };
        },

        actions: {
            async downloadAttendance(params: any): Promise<void> {
                const AuthStore = useAuthStore();
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(baseUrl).get<
                    ApiResponseInterface<any>
                >(
                    "/download-employee-attendances",
                    { params },
                    AuthStore.token,
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                const fileName =
                    moment(params?.range.from).format("YYYY_MM_DD_HH_mm_ss") +
                    "-" +
                    moment(params?.range.to).format("YYYY_MM_DD_HH_mm_ss") +
                    "_attendances.xlsx";

                fileLink.href = fileURL;
                fileLink.setAttribute("download", fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
            },
            async exportAttendance(params: any): Promise<void> {
                const AuthStore = useAuthStore();
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(baseUrl).get<
                    ApiResponseInterface<any>
                >(
                    "/export-employee-attendances",
                    { params },
                    AuthStore.token,
                    true
                );
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                const fileName =
                    moment(params?.range.from).format("YYYY_MM_DD_HH_mm_ss") +
                    "-" +
                    moment(params?.range.to).format("YYYY_MM_DD_HH_mm_ss") +
                    "_attendances.xlsx";

                fileLink.href = fileURL;
                fileLink.setAttribute("download", fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
            },
            async getAttendances(params: any): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeAttendacePaginatedInterface>
                >("/employee-attendances", { params });

                this.attendances = data;
            },

            async addAttendance(
                attendance: EmployeeAttendanceInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<EmployeeAttendanceInterface>
                >("/employee-attendances", attendance);

                this.attendances.data.push(data);
            },

            async updateAttendance(
                attendance: EmployeeAttendanceInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<EmployeeAttendanceInterface>
                >(`/employee-attendances/${attendance.id}`, attendance);

                const index = this.attendances.data.findIndex(
                    (a) => a.id === attendance.id
                );

                this.attendances.data[index] = data;
            },

            async deleteAttendance(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete<ApiResponseInterface<any>>(
                    `/employee-attendances/${id}`
                );

                const index = this.attendances.data.findIndex(
                    (a) => a.id === id
                );

                this.attendances.data.splice(index, 1);
            },

            async late(payload: any): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).post<ApiResponseInterface<any>>(
                    `/employee-late-attendance`,
                    payload
                );
            },

            async getNoClockIn(params: any): Promise<void> {
                console.log("getNoClockIn");
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(baseUrl).get<
                    EmployeeScheduleInterface[]
                >("/employee-schedules/no-clock-in", { params });

                this.schedules = response;
            },

            async showSchedule(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(
                    baseUrl
                ).get<EmployeeScheduleInterface>(`/employee-schedules/${id}`);

                this.schedule = response;
            },
        },
    }
);
