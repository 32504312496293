<script lang="ts" setup>
import { useClockInsStore } from "../../../stores/clockInsStore";
import moment from "moment-timezone";
import { computed } from "vue";
import { useAuthStore } from "../../../stores/authStore";

const ClockInsStore = useClockInsStore();
const AuthStore = useAuthStore();

const formattedStartTime = computed(() => {
    //removed .tz("Asia/Manila") because of the error discovered while testing in a machine in US time
    const startTime = moment(ClockInsStore.myAttendance.start_time);
    return startTime.isValid() ? startTime.format("HH:mm:ss") : "--:--";
});

const formattedEndTime = computed(() => {
    //removed .tz("Asia/Manila") because of the error discovered while testing in a machine in US time
    const endTime = moment(ClockInsStore.myAttendance.end_time);
    return endTime.isValid() ? endTime.format("HH:mm:ss") : "--:--";
});
</script>

<template>
    <div
        class="mt-6 text-left grid grid-cols-3 gap-2 bg-slate-100 p-4 rounded shadow-lg"
    >
        <div class="flex items-center">
            <div class="pl-1 dark:border-primary">
                <span class="font-medium">Clock In</span>
                <div class="text-slate-500">
                    {{ formattedStartTime }}
                </div>
            </div>
        </div>
        <div class="flex ml-4">
            <!-- Add ml-auto class here -->
            <div class="pl-4 dark:border-primary -ml-4">
                <span class="font-medium">Clock Out</span>
                <div class="text-slate-500">
                    {{ formattedEndTime }}
                </div>
            </div>
        </div>
        <div class="flex items-center ml-auto">
            <div class="pl-1 dark:border-primary text-center">
                <span class="font-medium">Total Hrs</span>
                <div class="text-green-700">
                    {{ ClockInsStore.myAttendance.total_hours }}
                </div>
            </div>
        </div>
        <template v-for="shiftBreak in ClockInsStore.myAttendance.breaks">
            <div class="flex items-center">
                <div class="pl-1 dark:border-primary">
                    <span class="font-medium">Break Start</span>
                    <div class="text-slate-500">
                        {{
                            shiftBreak.start_time
                                ? moment(shiftBreak.start_time).format(
                                      "HH:mm:ss"
                                  )
                                : "--:--"
                        }}
                    </div>
                </div>
            </div>
            <div class="flex items-center ml-4">
                <!-- Add ml-auto class here -->
                <div class="pl-4 dark:border-primary -ml-4">
                    <span class="font-medium">Break End</span>
                    <div class="text-slate-500">
                        {{
                            shiftBreak.end_time
                                ? moment(shiftBreak.end_time).format("HH:mm:ss")
                                : "--:--"
                        }}
                    </div>
                </div>
            </div>
            <div class="flex items-center ml-auto">
                <div class="pl-1 dark:border-primary text-center">
                    <span class="font-medium">Total Hrs</span>
                    <div class="text-green-700">
                        {{
                            shiftBreak.total_hours
                                ? shiftBreak.total_hours
                                : "0"
                        }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
