import { defineStore } from "pinia";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import ForgeBuildsInterface from "../pages/interfaces/forgeBuildsInterface";
import * as Sentry from "@sentry/browser";

export const useForgeBuildStore = defineStore("ForgeBuildStore", {
    state: (): {
        build: ForgeBuildsInterface | null;
        $sentry: null;
    } => {
        return {
            build: [] as unknown as ForgeBuildsInterface,
            $sentry: null,
        };
    },
    actions: {
        async getLatestBuild() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-latest-build");
                if (response.data) {
                    const { id, commit_hash, status, server } = response.data;
                    this.build = { id, commit_hash, status, server };
                } else {
                    // handle unexpected response data here
                }
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
    },
});
