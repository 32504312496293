<script lang="ts" setup>
import { defineProps, onMounted, ref } from "vue";
import { useHelperStore } from "../../../stores/helperStore";
import CustomIcon from "../../Icons/CustomIcon.vue";

const HelperStore = useHelperStore();

const props = defineProps({
    timezone: {
        type: String,
        default: "Asia/Manila",
    },
});

const currentTime = ref("");

onMounted(async () => {
    currentTime.value = "loading...";
    startClock();
});

const startClock = () => {
    const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: props.timezone,
    };
    //@ts-ignore
    const formatter = new Intl.DateTimeFormat([], options);

    setInterval(() => {
        const date = new Date();
        const asiaManilaTime = formatter.format(date);
        currentTime.value = adjustMidnightHour(asiaManilaTime);
    }, 1000);
};

const adjustMidnightHour = (timeString: any) => {
    if (timeString.startsWith("24")) {
        return "00" + timeString.slice(2);
    }
    return timeString;
};
</script>
<template>
    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
        <div class="report-box zoom-in">
            <div class="box p-5">
                <div class="flex">
                    <CustomIcon :height="24" :icon="'Clock'" :width="24" />
                </div>

                <div class="mt-6 flex">
                    <div>
                        <div class="text-3xl font-medium leading-8">
                            {{ currentTime }}
                        </div>

                        <div class="text-base text-slate-500 mt-1">
                            {{
                                props.timezone.split("/")[1].replace(/_/g, " ")
                            }}
                        </div>
                    </div>

                    <!--                    <div class="ml-auto">-->
                    <!--                        <div-->
                    <!--                            v-if="ampm === 'PM'"-->
                    <!--                            class="bg-custom-color-1000 text-white p-2 rounded-full shadow-md shadow-gray-500"-->
                    <!--                        >-->
                    <!--                            {{ ampm }}-->
                    <!--                        </div>-->
                    <!--                        <div-->
                    <!--                            v-else="ampm === 'PM'"-->
                    <!--                            class="bg-yellow-200 text-gray-500 p-2 rounded-full shadow-md shadow-gray-300"-->
                    <!--                        >-->
                    <!--                            {{ ampm }}-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
