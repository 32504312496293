<script lang="ts" setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { Dialog } from "../../base-components/Headless";
import Lucide from "../../base-components/Lucide";
import Button from "../../base-components/Button";

const props = defineProps({
    dialog: {
        type: Boolean,
        default: false,
    },
    mode: {
        type: String,
        default: "",
    },
    title: {
        type: String,
        default: "",
    },
    description: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["close"]);

const dialog = ref(false);
const toggle = () => {
    dialog.value = !dialog.value;
    emit("close");
};

onMounted(() => {
    if (props.dialog) {
        dialog.value = true;
    }
});

watch(
    () => props.dialog,
    (value: Boolean) => {
        if (value) {
            dialog.value = true;
        }
    }
);
</script>

<template>
    <Dialog :open="dialog" @close="toggle">
        <Dialog.Panel>
            <div class="p-5 text-center">
                <Lucide
                    icon="XCircle"
                    class="w-16 h-16 mx-auto mt-3 text-danger"
                />
                <div class="mt-5 text-3xl" v-if="props.title">
                    {{ props.title }}
                </div>

                <div class="mt-2 text-slate-500">
                    {{ props.description }}
                </div>
            </div>

            <div class="px-5 pb-8 text-center">
                <Button
                    type="button"
                    variant="outline-secondary"
                    @click="toggle()"
                    class="w-24 mr-1"
                >
                    Close
                </Button>
            </div>
        </Dialog.Panel>
    </Dialog>

    <!--    <Modal :show="dialog" @hidden="dialog = false">-->
    <!--        <a-->
    <!--            @click="cancel()"-->
    <!--            class="absolute right-0 top-0 mt-3 mr-3"-->
    <!--            href="javascript:;"-->
    <!--        >-->
    <!--            &lt;!&ndash; <XIcon class="w-8 h-8 text-slate-400"/> &ndash;&gt;-->
    <!--        </a>-->
    <!--        <ModalBody class="p-0">-->
    <!--            <div class="p-5 text-center">-->
    <!--                &lt;!&ndash;                <XCircleIcon v-if="mode === 'warning' || mode === 'danger'" class="w-16 h-16 text-danger mx-auto mt-3"/>&ndash;&gt;-->
    <!--                &lt;!&ndash;                <CheckIcon v-if="mode === 'success'" class="w-16 h-16 text-success mx-auto mt-3"/>&ndash;&gt;-->
    <!--                <div class="text-3xl mt-5">{{ subject }}</div>-->
    <!--                <div class="text-slate-500 mt-2">-->
    <!--                    {{ message }}-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="px-5 pb-8 text-center">-->
    <!--                <button-->
    <!--                    v-if="mode === 'warning' || mode === 'danger'"-->
    <!--                    type="button"-->
    <!--                    @click="execute()"-->
    <!--                    id="alert_modal_ok"-->
    <!--                    class="btn btn-warning w-24"-->
    <!--                >-->
    <!--                    Ok-->
    <!--                </button>-->
    <!--                <button-->
    <!--                    v-if="mode === 'warning' || mode === 'danger'"-->
    <!--                    type="button"-->
    <!--                    @click="cancel()"-->
    <!--                    id="alert_modal_cancel"-->
    <!--                    class="ml-4 btn btn-secondary w-24"-->
    <!--                >-->
    <!--                    cancel-->
    <!--                </button>-->
    <!--                <button-->
    <!--                    v-if="mode === 'success'"-->
    <!--                    type="button"-->
    <!--                    id="alert_modal_ok"-->
    <!--                    @click="cancel()"-->
    <!--                    class="btn btn-success w-24"-->
    <!--                >-->
    <!--                    Ok-->
    <!--                </button>-->
    <!--            </div>-->
    <!--        </ModalBody>-->
    <!--    </Modal>-->
</template>
