import { defineStore } from "pinia";
import {
    ClientInvoiceConfigInterface,
    ClientInvoiceConfigPaginatedInterface,
} from "../pages/interfaces/clientInvoiceConfigInterface";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

const baseUrl = import.meta.env.VITE_API_URL;

export const useClientInvoiceConfigStore = defineStore(
    "ClientInvoiceConfigStore",
    {
        state: (): {
            clientInvoiceConfigs: ClientInvoiceConfigPaginatedInterface;
            clientInvoiceConfig: ClientInvoiceConfigInterface;
            emails: "";
        } => {
            return {
                clientInvoiceConfigs:
                    {} as ClientInvoiceConfigPaginatedInterface,
                clientInvoiceConfig: {} as ClientInvoiceConfigInterface,
                emails: "",
            };
        },

        actions: {
            async getClientInvoiceConfigs(params: any): Promise<void> {
                const { data } = await new Http(
                    baseUrl
                ).get<ClientInvoiceConfigPaginatedInterface>(
                    "/client-invoice-configs",
                    { params }
                );

                this.clientInvoiceConfigs.data = data;
            },

            async getClientInvoiceConfig(id: number): Promise<void> {
                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<ClientInvoiceConfigInterface>
                >(`/client-invoice-configs/${id}`);

                this.clientInvoiceConfig = data;
            },

            async addClientInvoiceConfig(
                payload: ClientInvoiceConfigInterface
            ): Promise<void> {
                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<ClientInvoiceConfigInterface>
                >("/client-invoice-configs", payload);

                this.clientInvoiceConfigs.data.unshift(data);
            },

            async updateClientInvoiceConfig(
                payload: ClientInvoiceConfigInterface
            ): Promise<void> {
                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<ClientInvoiceConfigInterface>
                >(`/client-invoice-configs/${payload.id}`, payload);

                const index = this.clientInvoiceConfigs.data.findIndex(
                    (item) => item.id === data.id
                );

                this.clientInvoiceConfigs.data[index] = data;
            },

            async deleteClientInvoiceConfig(id: number): Promise<void> {
                await new Http(baseUrl).delete(`/client-invoice-configs/${id}`);

                this.clientInvoiceConfigs.data =
                    this.clientInvoiceConfigs.data.filter(
                        (item) => item.id !== id
                    );
            },

            async getClientConfigEmails(id: number) {
                this.emails = await new Http(baseUrl).get(
                    `/client-invoice-config-emails?client_id=${id}`
                );
            },
        },
    }
);
