<script setup lang="ts">
import { computed, ref, reactive, onMounted, defineProps } from "vue";
import { useDarkModeStore } from "../../../stores/dark-mode";
import { useColorSchemeStore } from "../../../stores/color-scheme";

import { usePipedriveStore } from "../../../stores/pipedriveStore";
import { getColor } from "../../../utils/colors";
import Chart from "../../../base-components/Chart";

const PipedriveStore = usePipedriveStore();

const props = defineProps({
    width: {
        type: [Number, String],
        default: "auto",
    },
    height: {
        type: [Number, String],
        default: "auto",
    },
    pendingEngagements: {
        type: Array,
        default: [],
    },
});

const darkMode = computed(() => useDarkModeStore().darkMode);
const colorScheme = computed(() => useColorSchemeStore().colorScheme);
//PipedriveStore.pendingEngagementTotals
const chartData = reactive({
    totals: [5, 10, 15, 15, 25, 30],
});
const labelNames = [];
const chartColors = () => [
    "#881337",
    "#701a75",
    "#701a75",
    "#581c87",
    "#4c1d95",
    "#312e81",
    "#0c4a6e",
];

onMounted(() => {
    PipedriveStore.pendingEngagements.filter((labels) => {
        //if (labels.total > 0) {
        labelNames.push(labels.name);
        //}
    });
});
const data = computed(() => {
    return {
        labels: [
            "New Arrivals",
            "Qualified",
            "Contact Made",
            "Prospect Qualified",
            "Needs Defined",
            "Proposal Made",
            "Negotiations Started",
            "Qualified",
            "Contact Made",
            "Demo Scheduled",
            "Proposal Made",
            "Negotiations Started",
        ],
        datasets: [
            {
                data: PipedriveStore.pendingEngagementTotals,
                backgroundColor: colorScheme.value ? chartColors() : "",
                hoverBackgroundColor: colorScheme.value ? chartColors() : "",
                borderWidth: 2,
                borderColor: darkMode.value
                    ? getColor("darkmode.700")
                    : getColor("white"),
            },
        ],
    };
});

const options = computed(() => {
    return {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: "83%",
    };
});
</script>
<template>
    <Chart
        type="doughnut"
        :width="+width"
        :height="+height"
        :data="data"
        :options="options"
    />
</template>
