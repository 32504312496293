import { defineStore } from "pinia";
import axios from "axios";
import _ from "lodash";
import { useAuthStore } from "./authStore";
import Http from "../services/Http";
import PipedriveInterface from "../pages/interfaces/pipedriveInterface";

export const usePipedriveStore = defineStore("PipedriveStore", {
    state: () => {
        return {
            pendingEngagements: [] as PipedriveInterface[],
            pendingEngagementTotals: [] as Array<number>,
        };
    },
    actions: {
        async fillPendingEngagements() {
            try {
                const baseUrl = import.meta.env.VITE_API_URL;
                const response = await new Http(baseUrl).get<any>(
                    "/get-all-pending-engagements"
                );

                this.pendingEngagements = response.data;
                this.pendingEngagementTotals = response.totals;

                return { status: "ok", data: response };
            } catch (error) {
                console.log(error);
            }
        },
    },
});
