import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import Http from "../services/Http";

export const useGlobalSearchStore = defineStore("GlobalSearchStore", {
    state: () => {
        return {
            globalSearchResults: [] as any[],
        };
    },
    actions: {
        async getGlobalSearchResults(keyword: string) {
            const AuthStore = useAuthStore();
            const response = await new Http(import.meta.env.VITE_API_URL).post<
                ApiResponseInterface<any[]>
            >("/global-search", { keyword });
            this.globalSearchResults = response.data;
        },
    },
});
