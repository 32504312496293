import { defineStore } from "pinia";
import { ClientNoteInterface } from "../pages/interfaces/clientNoteInterface";
import Http from "../services/Http";

interface ApiResponseInterface<T> {
    data: T;
    success: boolean;
}
export const useClientNoteStore = defineStore("ClientNoteStore", {
    state: (): {
        notes: ClientNoteInterface[];
        note: ClientNoteInterface;
    } => {
        return {
            notes: [],
            note: {} as ClientNoteInterface,
        };
    },
    actions: {
        async getClientNotes(client_id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            let url = "/client-notes";

            if (client_id) {
                url += `?client_id=${client_id}`;
            }

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<ClientNoteInterface[]>
            >(url);

            this.notes = data;
        },
        async addNote(payload: ClientNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<ClientNoteInterface>
            >("/client-notes", payload);

            this.notes.push(data);
        },
        async updateNote(payload: ClientNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<ClientNoteInterface>
            >(`/client-notes/${payload.id}`, payload);

            const index = this.notes.findIndex(
                (a: ClientNoteInterface) => a.id === data.id
            );

            Object.assign(this.notes[index], data);
        },
        async deleteNote(id: number) {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete<void>(`/client-notes/${id}`);

            const index = this.notes.findIndex(
                (a: ClientNoteInterface) => a.id === id
            );
            this.notes.splice(index, 1);
        },
    },
});
