import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import axios from "axios";
import RolesInterface from "../pages/interfaces/rolesInterface";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import * as Sentry from "@sentry/browser";

interface RolesAndPermissionStoreState {
    roles: RolesInterface[];
    roleGroups: any[];
    $sentry: typeof Sentry | null;
}

export const useRolesAndPermissionStore = defineStore(
    "RolesAndPermissionStore",
    {
        state: (): RolesAndPermissionStoreState => {
            return {
                roles: [] as RolesInterface[],
                roleGroups: [] as any[],
                $sentry: null,
            };
        },
        actions: {
            async fillRoles(): Promise<void> {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<RolesInterface[]>>("/get-roles");
                    this.roles = response.data;
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },
            async fillRoleGroups() {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<any[]>>(
                        "/get-permissions-groups"
                    );
                    this.roleGroups = response.data;
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },
            async insertPermissionToRole(formData: any) {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).post<ApiResponseInterface<any>>(
                        "/assign-permission-to-role",
                        formData
                    );
                    console.log("insertPermissionToRole success");
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(
                        "useRolesAndPermissionStore::insertPermissionToRole"
                    );
                    console.log(error);
                }
            },
            async deletePermissionFromRole(formData: any) {
                try {
                    const AuthStore = useAuthStore();
                    let options = {
                        method: "DELETE",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + AuthStore.token,
                        },
                        url:
                            import.meta.env.VITE_API_URL +
                            "/revoke-permission-to-role",
                        data: formData,
                    };
                    await axios.request(options).then((response) => {
                        console.log("deletePermissionFromRole success");
                    });
                } catch (error) {
                    console.log(
                        "useRolesAndPermissionStore::deletePermissionFromRole"
                    );
                    console.log(error);
                }
            },
            async insertPermissionToUser(formData: any): Promise<void> {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).post<ApiResponseInterface<any>>(
                        "/assign-permission-to-user",
                        formData
                    );
                    console.log("insertPermissionToUser success");
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(
                        "useRolesAndPermissionStore::insertPermissionToUser"
                    );
                    console.log(error);
                }
            },
            async deletePermissionFromUser(formData: any): Promise<void> {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).delete("/revoke-permission-to-user", formData);
                    console.log("deletePermissionFromUser success");
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(
                        "useRolesAndPermissionStore::deletePermissionFromUser"
                    );
                    console.log(error);
                }
            },
        },
    }
);
