import { defineStore } from "pinia";
import _ from "lodash";
import { useAuthStore } from "./authStore";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";

export default interface Contact {
    id: number;
    first_name: string;
    last_name: string;
    title: string;
    email: string;
    phone_number: string;
    primary_poc_flag: boolean;
    client_notification_flag: boolean;
    timezone_id: number;
    points_of_contact_role_id: number;
    client_id: number;
}

interface PointOfContactStoreState {
    contacts: Contact[];
    mode: string;
    formData: any[];
    roles: any[];
    contact: any[];
    $sentry: typeof Sentry | null;
}

interface ApiResponse<T> {
    data: T;
    status: T;
}

export const usePointOfContactStore = defineStore("PointOfContactStore", {
    state: (): PointOfContactStoreState => {
        return {
            contacts: [],
            mode: "Add",
            formData: [],
            roles: [],
            contact: [],
            $sentry: null,
        };
    },
    getters: {
        getMode(): string {
            return this.mode;
        },
    },
    actions: {
        async fill(params: any): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponse<any>>("/poc", { params });
                this.contacts = response.data.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getClients(params: any): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponse<any>>("/poc-detail", { params });
                this.contacts = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getRoles() {
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponse<any>>("/poc-roles", AuthStore.token);
                this.roles = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        getClient(id: number) {
            const Contact = _.find(this.contacts, (client) => {
                return client.id === id;
            });

            if (typeof Contact === "undefined") {
                this.mode = "Add";
                return {
                    id: 0,
                    first_name: "",
                    last_name: "",
                    title: "",
                    email: "",
                    phone_number: "",
                    primary_poc_flag: false,
                    client_notification_flag: true,
                    timezone_id: 1,
                    points_of_contact_role_id: 1,
                    client_id: 1,
                };
            } else {
                this.mode = "Update";
                return Contact;
            }
        },
        async save(formData: Contact) {
            let data = [];
            let status: any[] = [];
            try {
                const AuthStore = useAuthStore();

                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponse<any>>("/poc", formData, AuthStore.token);
                status = response.status;
                data = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                console.log(error);
            }
            return {
                status: status,
                data: data,
            };
        },
        async delete(id: number) {
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponse<any>>("/poc", { id: id }, AuthStore.token);
                return {
                    status: response.status,
                };
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
    },
});
