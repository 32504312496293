import { defineStore } from "pinia";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import { ScheduleTodayPaginatedInterface } from "./scheduleTodayInterface";

export const useScheduleToday = defineStore("ScheduleTodayStore", {
    state: (): {
        schedules: ScheduleTodayPaginatedInterface;
    } => {
        return {
            schedules: {} as ScheduleTodayPaginatedInterface,
        };
    },

    actions: {
        async getSchedules(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<ScheduleTodayPaginatedInterface>
            >("/employee-schedule-today", { params });

            this.schedules = data;
        },
    },
});
