<script lang="ts" setup>
import { defineEmits, defineProps, reactive } from "vue";
import { useRolesAndPermissionStore } from "../../stores/rolesAndPermissionsStore";
import { useUserStore } from "../../stores/userStore";
import { FormSwitch } from "../../base-components/Form";

const RolesAndPermissionStore = useRolesAndPermissionStore();
const UserStore = useUserStore();

interface PermissionItem {
    friendlyName: string;
    permission_id: any; // Adjust the type as needed
}

const props = defineProps({
    panelTitle: String, // Assuming panelTitle is of type string
    data: Array as () => PermissionItem[], // Define the type of data
    role: String, // Assuming role is of type string
});

const emit = defineEmits(["response"]);

const formData = reactive({
    role_id: "",
    permission_id: "",
    user_id: 0,
});

const updateRole = (event: any, permission_id: any) => {
    formData.user_id = UserStore.userDetail.id;
    formData.permission_id = permission_id;

    if (event.target.checked) {
        RolesAndPermissionStore.insertPermissionToUser(formData);
    } else {
        RolesAndPermissionStore.deletePermissionFromUser(formData);
    }
    emit("response", true);
};
</script>
<template>
    <div class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg">
        <div
            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
        >
            <h2 class="font-medium text-base mr-auto mb-4">{{ panelTitle }}</h2>
        </div>
        <div class="mt-3">
            <div class="mt-4">
                <div v-for="permission in data" class="mt-2">
                    <div class="mt-4 flex flex-row align-center items-center">
                        <FormSwitch>
                            <FormSwitch.Input
                                type="checkbox"
                                v-bind:checked="
                                    UserStore.userDetail.activatedUserPermissionsId.includes(
                                        permission.permission_id
                                    )
                                "
                                @change="
                                    updateRole($event, permission.permission_id)
                                "
                            />
                        </FormSwitch>

                        <label class="ml-2">{{
                            permission.friendlyName
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
