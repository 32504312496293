import { defineStore } from "pinia";
import { EmployeeAttendacePaginatedInterface } from "../pages/interfaces/employeeAttendanceInterface";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

export const useMyTimesheetStore = defineStore("MyTimesheetStore", {
    state: (): {
        attendances: EmployeeAttendacePaginatedInterface;
    } => {
        return {
            attendances: {} as EmployeeAttendacePaginatedInterface,
        };
    },

    actions: {
        async getAttendances(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeAttendacePaginatedInterface>
            >("/my-timesheets", { params });

            this.attendances = data;
        },
    },
});
