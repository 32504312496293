<template>
    <div class="intro-y box mt-5 lg:mt-0 mb-5 animate-pulse">
        <div class="relative flex items-center py-3 px-4">
            <div class="h-4 bg-gray-200 rounded w-1/3"></div>
            <div class="h-6 bg-gray-200 rounded ml-auto"></div>
        </div>
        <div class="p-2 border-t border-gray-200">
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
                        <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
                        <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
                    </div>
                    <div class="h-6 bg-gray-200 rounded"></div>
                </li>
            </ul>
        </div>
    </div>
</template>
