import { defineStore } from "pinia";
import { ClientEmployeeRolePaginatedInterface } from "../pages/interfaces/clientEmployeeRoleInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";

export const useClientEmployeeRoleStore = defineStore(
    "ClientEmployeeRoleStore",
    {
        state: (): {
            clientEmployeeRoles: ClientEmployeeRolePaginatedInterface;
            $sentry: typeof Sentry | null;
        } => {
            return {
                clientEmployeeRoles: {} as ClientEmployeeRolePaginatedInterface,
                $sentry: null,
            };
        },

        actions: {
            async getAllClientEmployeeRoles(params: any): Promise<void> {
                try {
                    const { data } = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<
                        ApiResponseInterface<ClientEmployeeRolePaginatedInterface>
                    >("/client-employee-roles", { params });
                    this.clientEmployeeRoles = data;
                } catch (e) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(e);
                    }

                    throw e;
                }
            },
            async saveClientEmployee(payload: any) {
                try {
                    await new Http(import.meta.env.VITE_API_URL).post<
                        ApiResponseInterface<any>
                    >("/client-employee-roles", payload);
                } catch (e) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(e);
                    }

                    throw e;
                }
            },
            async deleteClientEmployeeRole(id: number) {
                try {
                    await new Http(import.meta.env.VITE_API_URL).delete<
                        ApiResponseInterface<any>
                    >(`/client-employee-roles/${id}`);

                    const index = this.clientEmployeeRoles.data.findIndex(
                        (a) => a.id === id
                    );
                    this.clientEmployeeRoles.data.splice(index, 1);
                } catch (e) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(e);
                    }

                    throw e;
                }
            },
        },
    }
);
