import moment from "moment";
import { ref } from "vue";

export default function useTimeCalculator() {
    const total = ref(0);

    const calculate = (startTime: string, endTime: string) => {
        if (!startTime || !endTime) {
            total.value = 0;

            return;
        }

        const now = moment().utc().format("YYYY-MM-DD");

        const start = moment.utc(now + " " + startTime, "YYYY-MM-DDTHH:mm");
        const end = moment.utc(now + " " + endTime, "YYYY-MM-DDTHH:mm");

        if (end.isBefore(start)) {
            end.add(1, "day");
        }

        total.value = parseFloat(end.diff(start, "hours", true).toFixed(2));

        if (total.value < 1) {
            total.value = end.diff(start, "minutes", true) / 60;
        }
    };

    return {
        total,
        calculate,
    };
}
