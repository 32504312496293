<script lang="ts" setup>
import { FormInput, FormSwitch } from "../../../base-components/Form";
import { useShiftStore } from "../../../stores/shiftStore";
import Button from "../../../base-components/Button";
import FormLabel from "../../../base-components/Form/FormLabel.vue";
import Lucide from "../../../base-components/Lucide";
import TomSelect from "../../../base-components/TomSelect";
import useTimeCalculator from "../../../composables/time-calculator";
import { ref } from "vue";

const ShiftStore = useShiftStore();
const TimeCalculator = useTimeCalculator();

enum BreakTypeEnum {
    FIXED_BREAK = "1",
    FLEXI_BREAK = "2",
    FLOATING_BREAK = "3",
}

const breakDurations = ref(["15", "30", "45", "60"]);

const defaultBreaks = ref([
    {
        friendly_name: "Lunch Break",
        shift_break_type_id: BreakTypeEnum.FLEXI_BREAK,
        start_time: "",
        end_time: "",
        total_hours: "60",
        is_paid: false,
    },
    {
        friendly_name: "Coffee Break 1",
        shift_break_type_id: BreakTypeEnum.FLEXI_BREAK,
        start_time: "",
        end_time: "",
        total_hours: "15",
        is_paid: true,
    },
    {
        friendly_name: "Coffee Break 2",
        shift_break_type_id: BreakTypeEnum.FLEXI_BREAK,
        start_time: "",
        end_time: "",
        total_hours: "15",
        is_paid: true,
    },
]);

const isDefaultBreakLoaded = ref(false);

const props = defineProps({
    errors: {
        type: Object,
        default: () => ({}),
    },
    breaks: {
        type: Array as () => any[],
        default: () => [],
    },
    validate: {
        type: Object,
        default: () => ({}),
    },
    editing: {
        type: Boolean,
        default: false,
    },
});

const addShiftBreak = () => {
    props.breaks.push({
        friendly_name: "",
        shift_break_type_id: "",
        start_time: "",
        end_time: "",
        total_hours: 0,
        is_paid: false,
    });
};

const loadDefaultShift = () => {
    isDefaultBreakLoaded.value = true;

    Object.assign(props.breaks, defaultBreaks.value);
};

const calculateTotalHours = (item: any, event: any) => {
    const { name, value } = event.target;

    if (name === "start_time") {
        item.start_time = value;
    } else {
        item.end_time = value;
    }

    if (item.shift_break_type_id == BreakTypeEnum.FIXED_BREAK) {
        TimeCalculator.calculate(item.start_time, item.end_time);
        item.total_hours = parseFloat(TimeCalculator.total.value.toFixed(2));
    }
};

const onClickTrash = (item: any) => {
    const index = props.breaks.indexOf(item);

    if (props.breaks.length > -1) {
        props.breaks.splice(index, 1);
    }
};

const onChangeShiftBreak = (item: any, event: any) => {
    item.start_time = "";
    item.end_time = "";
    item.total_hours = 0;
    item.shift_break_type_id = event === "Select Break Type" ? "" : event;
};

const test = (item: any) => {
    console.log(item);
};
</script>

<template>
    <div
        v-if="props.errors.shift_breaks"
        class="p-3 mb-4 rounded-md font-bold bg-red-200 text-red-600"
    >
        {{ props.errors.shift_breaks[0] }}
    </div>

    <div class="flex justify-between align-center">
        <h4 class="font-bold">Breaks</h4>

        <div class="flex justify-between">
            <Button class="outline" type="button" @click="addShiftBreak"
                >Add
            </Button>

            <Button
                id="load-default"
                v-if="!props.editing && !isDefaultBreakLoaded"
                class="ml-1"
                type="button"
                variant="primary"
                @click="loadDefaultShift()"
                >Load Default
            </Button>
        </div>
    </div>

    <div v-for="(item, index) in breaks" :key="index">
        <div class="mt-4">
            <div class="flex justify-between align-center">
                <FormLabel class="form-label">&nbsp;</FormLabel>

                <div v-if="index !== 0">
                    <Lucide
                        class="w-4 h-4 text-red-500 hover:text-red-800 cursor-pointer"
                        icon="Trash"
                        @click="onClickTrash(item)"
                    />
                </div>
            </div>

            <div class="mt-4">
                <FormLabel class="form-label" for="friendly_name">
                    Friendly Name
                    <span class="text-red-600">*</span>
                </FormLabel>
                <FormInput
                    id="friendly_name"
                    v-model="item.friendly_name"
                    :value="item.friendly_name"
                    class="form-control w-full"
                    maxlength="50"
                    name="friendly_name"
                    placeholder=""
                    type="text"
                />
                <template
                    v-if="
                        props.validate.shift_breaks.$errors[0]?.$response
                            ?.$errors[index].friendly_name
                    "
                >
                    <div
                        v-for="(error, errorIndex) in props.validate
                            .shift_breaks.$errors[0]?.$response?.$errors[index]
                            .friendly_name"
                        :key="errorIndex"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>

                <template
                    v-if="props.errors[`shift_breaks.${index}.friendly_name`]"
                >
                    <div
                        v-for="(error, errorIndex) in props.errors[
                            `shift_breaks.${index}.friendly_name`
                        ]"
                        :key="errorIndex"
                        class="text-danger mt-2"
                    >
                        {{ error }}
                    </div>
                </template>
            </div>

            <div class="mt-4">
                <FormLabel class="form-label" for="shift_break_type_id">
                    Shift Break Type
                    <span class="text-red-600">*</span>
                </FormLabel>

                <TomSelect
                    :id="`shift_break_type_id_${index}`"
                    :modelValue="item.shift_break_type_id"
                    class="w-full p-0"
                    name="shift_break_type_id"
                    @update:modelValue="onChangeShiftBreak(item, $event)"
                >
                    <option selected>Select Break Type</option>
                    <option
                        v-for="breakType of ShiftStore.shiftBreakTypes"
                        :value="breakType.id"
                    >
                        {{ breakType.name }}
                    </option>
                </TomSelect>
            </div>
            <template
                v-if="
                    props.validate.shift_breaks.$errors[0]?.$response?.$errors[
                        index
                    ].shift_break_type_id
                "
            >
                <div
                    v-for="(error, errorIndex) in props.validate.shift_breaks
                        .$errors[0]?.$response?.$errors[index]
                        .shift_break_type_id"
                    :key="errorIndex"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>

            <template
                v-if="props.errors[`shift_breaks.${index}.shift_break_type_id`]"
            >
                <div
                    v-for="(error, errorIndex) in props.errors[
                        `shift_breaks.${index}.shift_break_type_id`
                    ]"
                    :key="errorIndex"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="grid grid-cols-12 gap-6">
            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="name">Start Time</FormLabel>
                <FormInput
                    :id="`break_start_time_${index}`"
                    v-model="item.start_time"
                    :disabled="
                        item.shift_break_type_id ===
                        BreakTypeEnum.FLOATING_BREAK
                    "
                    :value="item.start_time"
                    class="form-control w-full"
                    maxlength="50"
                    name="start_time"
                    placeholder=""
                    type="time"
                    @change="calculateTotalHours(item, $event)"
                />
                <template
                    v-if="props.errors[`shift_breaks.${index}.start_time`]"
                >
                    <div class="text-danger mt-2">
                        {{
                            props.errors[`shift_breaks.${index}.start_time`][0]
                        }}
                    </div>
                </template>
            </div>

            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="name">End Time</FormLabel>
                <FormInput
                    :id="`break_end_time_${index}`"
                    v-model="item.end_time"
                    :disabled="
                        item.shift_break_type_id ===
                        BreakTypeEnum.FLOATING_BREAK
                    "
                    :value="item.end_time"
                    class="form-control w-full"
                    maxlength="50"
                    name="end_time"
                    placeholder=""
                    type="time"
                    @change="calculateTotalHours(item, $event)"
                />
                <template v-if="props.errors[`shift_breaks.${index}.end_time`]">
                    <div class="text-danger mt-2">
                        {{ props.errors[`shift_breaks.${index}.end_time`][0] }}
                    </div>
                </template>
            </div>
        </div>

        <div class="grid grid-cols-12 gap-6">
            <div class="mt-4 col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="name"
                    >Break Hours
                </FormLabel>

                <div
                    v-if="
                        item.shift_break_type_id === BreakTypeEnum.FIXED_BREAK
                    "
                >
                    <FormInput
                        :id="`break_total_hours_${index}`"
                        v-model="item.total_hours"
                        :disabled="
                            item.shift_break_type_id ===
                            BreakTypeEnum.FIXED_BREAK
                        "
                        :value="item.total_hours"
                        class="form-control w-full"
                        maxlength="50"
                        name="total_hours"
                        placeholder=""
                        type="text"
                    />
                </div>

                <div v-else>
                    <TomSelect
                        :id="`break_total_hours_${index}`"
                        :modelValue="String(item.total_hours)"
                        @update:model-value="item.total_hours = $event"
                        class="w-full p-0"
                        name="shift_break_type_id"
                    >
                        <option selected>Select Break Duration</option>
                        <option
                            v-for="duration in breakDurations"
                            :value="duration"
                        >
                            {{ duration }}
                        </option>
                    </TomSelect>
                </div>

                <template
                    v-if="props.errors[`shift_breaks.${index}.total_hours`]"
                >
                    <div
                        v-for="(error, errorIndex) in props.errors[
                            `shift_breaks.${index}.total_hours`
                        ]"
                        :key="errorIndex"
                        class="text-danger mt-2"
                    >
                        {{ error }}
                    </div>
                </template>
            </div>

            <div class="mt-4 col-span-12 lg:col-span-6">
                <label>Paid Break</label>
                <FormSwitch class="mt-3">
                    <FormSwitch.Input
                        v-model="item.is_paid"
                        type="checkbox"
                        v-bind:checked="item.is_paid"
                    />
                </FormSwitch>
            </div>
        </div>
    </div>
</template>
