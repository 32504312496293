<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { defineEmits, defineProps } from "vue";

const props = defineProps({
    subject: String,
    message: String,
});

const emit = defineEmits(["output"]);

const AuthStore = useAuthStore();
</script>

<template>
    <div id="success-notification" class="toastify-content hidden flex">
        <!--        <CheckCircleIcon class="text-success" />-->
        <div class="ml-4 mr-4">
            <div class="font-medium">{{ message }}</div>
            <div class="text-slate-500 mt-1">
                {{ subject }}
            </div>
        </div>
    </div>
</template>
