import { defineStore } from "pinia";
import UsersInterface from "../pages/interfaces/usersInterface";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

interface UserDetail {
    id: number;
    name: string;
    role: string;
    email: string;
    activatedUserPermissionsId: number[];
    // Add other properties here if needed
}

interface UserStoreState {
    users: any[];
    userDetail: UserDetail; // Change type to UserDetail
    usersForNotifications: UsersInterface[];
    $sentry: typeof Sentry | null;
}

export const useUserStore = defineStore("UserStore", {
    state: () => {
        return {
            userList: [] as UsersInterface[],
            users: {} as ApiResponseInterface<UsersInterface>,
            userDetail: {} as UserDetail, // Initialize as an empty object
            usersForNotifications: [] as UsersInterface[],
            $sentry: null,
        };
    },
    actions: {
        async getUsersForNotifications() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-all-notification-users");
                this.usersForNotifications = response.data;
                console.log(
                    "usersForNotifications:",
                    this.usersForNotifications
                );
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getUserDetails(id: any): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-user?id=" + id);
                this.userDetail = {
                    id: response.data.id,
                    name: response.data.name,
                    role: response.data.role,
                    email: response.data.email,
                    activatedUserPermissionsId:
                        response.data.activatedUserPermissionsId,
                };
                console.log("getUserDetails filled");
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async fill(params: any): Promise<void> {
            console.log("filling users");
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-users", {
                    params: params,
                });
                this.users = response.data;
                console.log("users filled");
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getUsers(params: any): Promise<void> {
            try {
                this.userList = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<UsersInterface[]>(`/users?${params}`);
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
    },
});
