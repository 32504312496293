import { defineStore } from "pinia";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
import { EmployeeSliderInterface } from "../pages/interfaces/dashboardInterface";

export const useDashboardStore = defineStore("DashboardStore", {
    state: () => {
        return {
            employeeSlider: {} as EmployeeSliderInterface,
            hrSlider: {
                clients: 0,
                employees: 0,
                userClientInfo: {
                    client: { legal_business_name: "", timezone_id: 0 },
                },
            },
            mode: "",
            $sentry: null,
        };
    },
    getters: {
        getMode(): string {
            return this.mode;
        },
    },
    actions: {
        async employeeDashboardfill() {
            try {
                const baseUrl = import.meta.env.VITE_API_URL;

                const response = await new Http(
                    baseUrl
                ).get<EmployeeSliderInterface>("/employee-dashboard");

                this.employeeSlider = response;
            } catch (error) {
                console.log(error);
            }
        },
        async hrDashboardfill() {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/hr-dashboard");
                this.hrSlider = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
    },
});
