<script lang="ts" setup>
import { getCurrentInstance, ref } from "vue";
import { useAuthStore } from "../../../stores/authStore";
import { useClockInsStore } from "../../../stores/clockInsStore";
import dayjs from "dayjs";

const AuthStore = useAuthStore();
const instance = getCurrentInstance();
const myShift = ref(AuthStore.myShift);
const ClockInStore = useClockInsStore();

const getFormattedDate = () => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "Asia/Manila",
    };
    return date.toLocaleDateString(undefined, options);
};

const currentDate = ref(getFormattedDate());
</script>
<template>
    <div class="p-5 mt-2 box bg-custom-color-1000 intro-x shadow-lg">
        <div class="flex flex-wrap gap-3">
            <div class="mr-auto">
                <div
                    class="flex items-center leading-3 text-white text-opacity-70 dark:text-slate-300"
                >
                    <template v-if="ClockInStore.myAttendance.start_time">
                        <span>Current Shift for</span>

                        <span class="ml-1 text-white">{{
                            dayjs(ClockInStore.myAttendance.start_time).format(
                                "MMMM DD, YYYY"
                            )
                        }}</span>
                    </template>

                    <template v-else>
                        <span>Starting shift for</span>
                        <span class="ml-1 text-white">{{ currentDate }}</span>
                    </template>
                </div>
                <div
                    class="flex text-white relative text-lg font-medium leading-5 mt-3.5"
                >
                    {{ AuthStore.myShift.name }}
                </div>
            </div>
        </div>
    </div>
</template>
