<script lang="ts" setup>
import { onMounted, provide, ref } from "vue";
import WidgetTime from "../pages/components/dashboard/WidgetTime.vue";
import PrimeCalendar from "../pages/components/dashboard/PrimeCalendar.vue";
import WidgetClientInfo from "../pages/components/dashboard/WidgetClientInfo.vue";
import { useAuthStore } from "../stores/authStore";
import { useDashboardStore } from "../stores/dashboardStore";
import router from "../router";
import { isset } from "../utils/helper";
import { useUiStore } from "../stores/uiStore";
import MyTickets from "./components/dashboard/MyTickets.vue";

const importantNotesRef = ref();
const AuthStore = useAuthStore();
const DashboardStore = useDashboardStore();
const clientInfo = ref();
const UiStore = useUiStore();

provide("bind[importantNotesRef]", (el: any) => {
    importantNotesRef.value = el;
});

onMounted(async () => {
    UiStore.setBreadcrumb("Dashboard");
});

const photoRandomizer = (number: any) => {
    return "../../images/a-" + number + ".png";
};

const prevImportantNotes = () => {
    const el = importantNotesRef.value;
    el.tns.goTo("prev");
};

const nextImportantNotes = () => {
    const el = importantNotesRef.value;
    el.tns.goTo("next");
};

const viewEmployee = (id: number) => {
    console.log("viewEmployee", id);
    router.push({ name: "employeesDetails", params: { id: id } });
};
</script>

<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-9">
            <div class="grid grid-cols-12 gap-6">
                <!-- BEGIN: General Report -->
                <div class="col-span-12 mt-8">
                    <div class="intro-y flex items-center h-10">
                        <h2
                            id="page-header"
                            class="text-lg font-medium truncate mr-5"
                        >
                            Dashboard
                        </h2>
                        <a
                            class="ml-auto flex items-center text-primary"
                            href=""
                        >
                            <!--                            <RefreshCcwIcon class="w-4 h-4 mr-3"/>-->
                            Reload Data
                        </a>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y"
                        >
                            <WidgetClientInfo></WidgetClientInfo>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y"
                        >
                            <MyTickets />
                        </div>
                        <!--                        <div-->
                        <!--                            class="col-span-12 md:col-span-6 xl:col-span-6 opacity-50 blur-sm"-->
                        <!--                        >-->
                        <!--                            <div class="intro-x">-->
                        <!--                                <div class="box zoom-in">-->
                        <!--                                    <TinySlider ref-key="importantNotesRef">-->
                        <!--                                        <div class="p-5">-->
                        <!--                                            <div-->
                        <!--                                                class="text-base font-medium truncate"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                            </div>-->
                        <!--                                            <div class="text-slate-400 mt-1">-->
                        <!--                                                20 Hours ago-->
                        <!--                                            </div>-->
                        <!--                                            <div-->
                        <!--                                                class="text-slate-500 text-justify mt-1"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                                of the printing and typesetting-->
                        <!--                                                industry. Lorem Ipsum has been-->
                        <!--                                                the industry's standard dummy-->
                        <!--                                                text ever since the 1500s.-->
                        <!--                                            </div>-->
                        <!--                                            <div class="font-medium flex mt-5">-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-secondary py-1 px-2"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    View Announcement-->
                        <!--                                                </button>-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    Dismiss-->
                        <!--                                                </button>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                        <div class="p-5">-->
                        <!--                                            <div-->
                        <!--                                                class="text-base font-medium truncate"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                            </div>-->
                        <!--                                            <div class="text-slate-400 mt-1">-->
                        <!--                                                20 Hours ago-->
                        <!--                                            </div>-->
                        <!--                                            <div-->
                        <!--                                                class="text-slate-500 text-justify mt-1"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                                of the printing and typesetting-->
                        <!--                                                industry. Lorem Ipsum has been-->
                        <!--                                                the industry's standard dummy-->
                        <!--                                                text ever since the 1500s.-->
                        <!--                                            </div>-->
                        <!--                                            <div class="font-medium flex mt-5">-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-secondary py-1 px-2"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    View Announcement-->
                        <!--                                                </button>-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    Dismiss-->
                        <!--                                                </button>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                        <div class="p-5">-->
                        <!--                                            <div-->
                        <!--                                                class="text-base font-medium truncate"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                            </div>-->
                        <!--                                            <div class="text-slate-400 mt-1">-->
                        <!--                                                20 Hours ago-->
                        <!--                                            </div>-->
                        <!--                                            <div-->
                        <!--                                                class="text-slate-500 text-justify mt-1"-->
                        <!--                                            >-->
                        <!--                                                Lorem Ipsum is simply dummy text-->
                        <!--                                                of the printing and typesetting-->
                        <!--                                                industry. Lorem Ipsum has been-->
                        <!--                                                the industry's standard dummy-->
                        <!--                                                text ever since the 1500s.-->
                        <!--                                            </div>-->
                        <!--                                            <div class="font-medium flex mt-5">-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-secondary py-1 px-2"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    View Announcement-->
                        <!--                                                </button>-->
                        <!--                                                <button-->
                        <!--                                                    class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"-->
                        <!--                                                    type="button"-->
                        <!--                                                >-->
                        <!--                                                    Dismiss-->
                        <!--                                                </button>-->
                        <!--                                            </div>-->
                        <!--                                        </div>-->
                        <!--                                    </TinySlider>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <!-- END: General Report -->
                <!-- BEGIN: Weekly Top Seller -->

                <!-- END: Weekly Top Seller -->
                <!-- BEGIN: Sales Report -->

                <!-- END: Sales Report -->

                <!-- BEGIN: Weekly Best Sellers -->
                <!-- END: Weekly Best Sellers -->
                <!-- BEGIN: General Report -->

                <!-- END: General Report -->
                <!-- BEGIN: Weekly Top Products -->

                <!-- END: Weekly Top Products -->
            </div>
        </div>
        <div class="col-span-12 2xl:col-span-3">
            <div class="2xl:border-l -mb-10 pb-10">
                <div
                    class="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6"
                >
                    <div
                        class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto mt-24"
                    >
                        <div
                            v-if="
                                isset(
                                    DashboardStore.employeeSlider.userClientInfo
                                )
                            "
                        >
                            <WidgetTime
                                :name="clientInfo"
                                :timezone="'America/New_York'"
                            ></WidgetTime>
                        </div>
                        <div class="mt-8">
                            <WidgetTime :timezone="'Asia/Manila'"></WidgetTime>
                        </div>
                    </div>

                    <div
                        class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3 2xl:mt-8"
                    >
                        <PrimeCalendar></PrimeCalendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
