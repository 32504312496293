import { defineStore } from "pinia";
import {
    EmployeeScheduleInterface,
    EmployeeSchedulePaginatedInterface,
} from "../pages/interfaces/employeeScheduleInterface";
import Http from "../services/Http";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

export const useEmployeeScheduleStore = defineStore("EmployeeScheduleStore", {
    state: (): {
        schedules: EmployeeSchedulePaginatedInterface;
        schedule: EmployeeScheduleInterface;
    } => {
        return {
            schedules: {} as EmployeeSchedulePaginatedInterface,
            schedule: {} as EmployeeScheduleInterface,
        };
    },

    actions: {
        async getSchedules(params: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeSchedulePaginatedInterface>
            >("/employee-schedules", { params });

            this.schedules = data;
        },
        async addSchedule(payload: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<EmployeeScheduleInterface>
            >("/employee-schedules", payload);

            this.schedules.data.push(data);
        },
        async updateSchedule(payload: any): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<EmployeeScheduleInterface>
            >(`/employee-schedules/${payload.id}`, payload);

            const index = this.schedules.data.findIndex(
                (schedule) => schedule.id === payload.id
            );

            console.log(data);

            this.schedules.data[index] = data;
        },
    },
});
