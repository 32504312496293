import { defineStore } from "pinia";
import Http from "@/services/Http";
import ApiResponseInterface from "@/pages/interfaces/apiResponseInterface";
import ClientRecurringSubscriptionInterface, {
    ClientRecurringSubscriptionPaginatedInterface,
} from "@/pages/interfaces/clientRecurringSubscriptionInterface";

export const useClientRecurringSubscriptionStore = defineStore(
    "ClientRecurringSubscription",
    {
        state: (): {
            clientRecurringSubscriptions: ClientRecurringSubscriptionPaginatedInterface;
            clientRecurringSubscription: ClientRecurringSubscriptionInterface;
        } => {
            return {
                clientRecurringSubscriptions:
                    {} as ClientRecurringSubscriptionPaginatedInterface,
                clientRecurringSubscription:
                    {} as ClientRecurringSubscriptionInterface,
            };
        },
        actions: {
            async getClientRecurringSubscriptions(params: any): Promise<void> {
                console.log("getClientRecurringSubscriptions");
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<ClientRecurringSubscriptionInterface[]>
                >(`/client-recurring-subscription`, params);
                console.log(data);
                this.clientRecurringSubscriptions.data = data;
            },

            async getClientRecurringSubscription(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<ClientRecurringSubscriptionInterface>
                >(`/client-recurring-subscription/${id}`);

                this.clientRecurringSubscription = data;
            },

            async addClientRecurringSubscription(
                payload: ClientRecurringSubscriptionInterface
            ): Promise<void> {
                console.log("addClientRecurringSubscription");
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<ClientRecurringSubscriptionInterface>
                >("/client-recurring-subscription", payload);

                this.clientRecurringSubscriptions.data.unshift(data);
            },

            async updateClientRecurringSubscription(
                payload: ClientRecurringSubscriptionInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<ClientRecurringSubscriptionInterface>
                >(`/client-recurring-subscription/${payload.id}`, payload);

                const index = this.clientRecurringSubscriptions.data.findIndex(
                    (item) => item.id === data.id
                );

                console.log(data);

                this.clientRecurringSubscriptions.data[index] = data;
            },

            async deleteClientRecurringSubscription(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(
                    `/client-recurring-subscription/${id}`
                );

                const index = this.clientRecurringSubscriptions.data.findIndex(
                    (item) => item.id === id
                );

                this.clientRecurringSubscriptions.data.splice(index, 1);
            },
        },
    }
);
