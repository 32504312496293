<script lang="ts" setup>
import {defineEmits, defineProps, onMounted, ref} from "vue";
import {useAuthStore} from "../../stores/authStore";
import {useEmployeesStore} from "../../stores/employeesStore";
import _ from "lodash";
import TomSelect from "../../base-components/TomSelect";
import {FormLabel} from "../../base-components/Form";

interface TeamMember {
    employee: Employee;
}

interface Employee {
    id: number;
    name: string;
    third_party_employee_id?: number;
}

const emit = defineEmits(["itemValue", "itemName"]);

const props = defineProps({
    validate: {
        type: Object as () => {
            [key: string]: any;
        },
        default: {}
    }
});

const AuthStore = useAuthStore();
const EmployeesStore = useEmployeesStore();
const employeeList = ref<Employee[]>([]);
const selectItem = ref("");

onMounted(async () => {
    if (canViewAllEmployees()) {
        await EmployeesStore.getAllEmployees();
        getAllEmployees();
    } else {
        getEmployeesFromMyTeam();
    }
});

const getValue = (item: any) => {
    const employeeDetails = _.find(employeeList.value, (employee) => {
        return employee.id === Number(selectItem.value);
    });
    if (employeeDetails) {
        emit("itemName", employeeDetails.name);
        emit("itemValue", selectItem.value);
    }
};
const getAllEmployees = () => {
    const employees = EmployeesStore.employees;
    employees.forEach((employee) => {
        if (employee.id !== AuthStore.authUser.id) {
            employeeList.value.push({
                id: employee.id,
                name:
                    employee.name +
                    " (" +
                    employee.third_party_employee_id +
                    ")",
            });
        }
    });
};
const getEmployeesFromMyTeam = () => {
    const myTeam = AuthStore.myTeam;
    myTeam.forEach((team) => {
        team.employeeTeams.forEach((member: TeamMember) => {
            if (member.employee.id !== AuthStore.authUser.id) {
                employeeList.value.push({
                    id: member.employee.id,
                    name:
                        member.employee.name +
                        " (" +
                        member.employee.third_party_employee_id +
                        ")",
                });
            }
        });
    });
};

const canViewAllEmployees = () => {
    return (
        //@ts-ignore
        window.Laravel.permissions.filter(
            (item: { name: string }) => item.name === "can view all employees"
        ).length >= 1
    );
};
</script>
<template>
    <div class="mt-3">
        <FormLabel class="form-label"
        >Employee / Team Member
            <span style="color: rgb(255, 0, 0)">*</span></FormLabel
        >
        <!--  v-model.trim="selectItem" -->
        <TomSelect
            id="employeeId"
            v-model="selectItem"
            :change="getValue(selectItem)"
            class="w-full"
            name="employeeId"
        >
            <option value="">Select Employee</option>
            <option v-for="employee of employeeList" :value="employee.id">
                {{ employee.name }}
            </option>
        </TomSelect>
    </div>
    <div class="mt-2">
        <template v-if="validate.employeeId.$error">
            <div
                v-for="(error, index) in validate.employeeId.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>
