<template>
    <div
        class="col-span-12 lg:col-span-4 2xl:col-span-4 flex lg:block flex-col-reverse"
    >
        <div class="intro-y box mt-5 lg:mt-0">
            <div class="relative flex items-center p-5">
                <div class="w-12 h-12 bg-gray-200 rounded-full"></div>
                <div class="ml-4 mr-auto w-4/5 pr-4">
                    <div class="h-5 bg-gray-200 rounded w-full"></div>
                    <div class="h-5 bg-gray-200 rounded w-3/4 mt-2"></div>
                </div>
                <div class="mb-2 mr-1 h-10 w-10 bg-gray-200 rounded"></div>
            </div>
            <div class="h-full bg-white rounded">
                <div class="p-2 border-t border-gray-200">
                    <ul
                        class="rounded-md divide-y divide-gray-200 mb-2"
                        role="list"
                    >
                        <li
                            class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                            <div class="ml-2 flex-1 w-0 truncate">
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-1/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-3/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-1/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-3/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-1/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-3/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-1/4"
                                ></div>
                                <div
                                    class="h-4 bg-gray-200 rounded mt-5 w-3/4"
                                ></div>
                            </div>
                            <div class="h-6 bg-gray-200 rounded"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
