import { defineStore } from "pinia";
import Http from "../services/Http";

export const useEmployeeShiftStore = defineStore("EmployeeShiftStore", {
    actions: {
        async save(payload: any) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).post<any>(
                `/employee-shifts`,
                payload
            );

            console.log(data);
        },

        async update(payload: any) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).put<any>(
                `/employee-shifts/${payload.id}`,
                payload
            );
        },

        async delete(id: number) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).delete<any>(
                `/employee-shifts/${id}`
            );

            console.log(data);
        },
    },
});
