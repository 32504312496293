import { defineStore } from "pinia";
import Http from "@/services/Http";
import ApiResponseInterface from "@/pages/interfaces/apiResponseInterface";
import * as Sentry from "@sentry/browser";
import ActivityLogInterface, {
    ActivityLogTableDataInterface,
} from "@/pages/interfaces/activityLogInterface";

interface ActivityLogsApiResponseInterface<T> {
    data: T;
    total?: number;
    scopes?: string[];
}

interface ShiftLogsStoreState {
    logs: ActivityLogInterface[];
    table: ActivityLogTableDataInterface;
    scopes?: string[];
    $sentry: typeof Sentry | null;
}

export const useShiftActivityLogsStore = defineStore("ShiftActivityLogsStore", {
    state: (): ShiftLogsStoreState => {
        return {
            logs: [],
            table: { data: [], total: 0 },
            scopes: [],
            $sentry: null,
        };
    },
    actions: {
        async fill(type: string = "all", id: number | null = null) {
            try {
                const params = new URLSearchParams();

                params.append("type", type);

                if (id) {
                    params.append("type_id", id.toString());
                }

                if (type === "all") {
                    params.delete("type");
                }

                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/get-all-shift-activity-logs?" + params
                );
                this.logs = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async fillTable(params: any) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ActivityLogsApiResponseInterface<any>>("/get-shift-activity-logs", {
                    params,
                });
                this.table = response.data;
                this.scopes = response.scopes;
            } catch (error) {
                console.log(error);
            }
        },
    },
    //persist: true
});
