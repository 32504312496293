<script setup lang="ts">
import { useRoute } from "vue-router";
import { FormLabel } from "../../../base-components/Form";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import { useVuelidate } from "@vuelidate/core";
import { reactive, ref, toRefs, defineEmits, onMounted } from "vue";
import Button from "../../../base-components/Button";
import { useClientNoteStore } from "../../../stores/clientNoteStore";
import { required } from "@vuelidate/validators";
import { ClientNoteInterface } from "../../interfaces/clientNoteInterface";

const props = defineProps({
    note: {
        type: Object,
        default: () => ({}),
    },
});
const emit = defineEmits(["close"]);

const route = useRoute();
const NoteStore = useClientNoteStore();
const form = reactive<ClientNoteInterface>({
    note: "",
    client_id: +route?.params?.id ?? 0,
});
const $externalResults = ref({});
const rules = {
    note: {
        required,
    },
};

const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const close = () => {
    emit("close");

    resetForm();
    validate.value.$reset();
};

const resetForm = () => {
    form.client_id = +route?.params?.id ?? 0;
    form.note = "";
};

const save = async () => {
    NoteStore.addNote(form);
};

const update = async () => {
    NoteStore.updateNote(form);
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (!validate.value.$invalid) {
        const { note } = toRefs(props);

        try {
            note.value.id ? await update() : await save();

            close();
        } catch (error: any) {
            $externalResults.value = error?.response?.data?.errors;
        }
    }
};

onMounted(() => {
    const { note } = toRefs(props);

    if (note.value.id) {
        form.id = note.value.id;
        form.client_id = note.value.client_id;
        form.note = note.value.note;
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="mt-4">
                        <FormLabel class="form-label" for="note"
                            >Note
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <FormTextarea
                            id="note"
                            v-model="form.note"
                            :value="form.note"
                            class="form-control w-full"
                            name="note"
                            placeholder="Write something..."
                        />
                        <template v-if="validate.note.$error">
                            <div
                                v-for="(error, index) in validate.note.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="">
                        <div class="text-right py-2">
                            <Button
                                id="btn_cancel"
                                class="w-32 mb-2 mr-1"
                                type="button"
                                variant="warning"
                                @click="close()"
                            >
                                Cancel
                            </Button>

                            <Button
                                id="btn_process"
                                class="w-36 mb-2"
                                type="submit"
                                variant="customPrimary"
                            >
                                Add Note
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style scoped></style>
