<script lang="ts" setup>
import { defineEmits, onMounted, ref, watch } from "vue";
import { FormInput, FormLabel } from "../../base-components/Form";
import { useAuthStore } from "../../stores/authStore";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useTeamStore } from "../../stores/teamStore";

const AuthStore = useAuthStore();
const EmployeeStore = useEmployeesStore();
const TeamStore = useTeamStore();
const emit = defineEmits(["itemValue"]);

const props = defineProps({
    employee: {
        type: String,
        default: "",
    },
});

const teamLeaders = ref("");

watch(
    () => props.employee,
    async () => {
        if (props.employee) {
            emit("itemValue", await getEmployeeValue());
        } else {
            emit("itemValue", await getValue());
        }
    }
);

onMounted(async () => {
    if (props.employee) {
        emit("itemValue", await getEmployeeValue());
    } else {
        const data = await getValue();
        emit("itemValue", data);
    }
});

let cachedValue: string[] = [];
const getValue = async () => {
    // debugger;
    if (cachedValue.length === 0) {
        let teams: string[] = [];
        await TeamStore.getMyTeam();
        const myTeam: any[] = TeamStore.myTeam;
        //const myTeam: any[] = AuthStore.myTeam;
        //@ts-ignore
        if (myTeam !== "") {
            myTeam.forEach((team) => {
                //@ts-ignore
                team.employeeTeams.forEach((member) => {
                    if (member.team_role_id === 1) {
                        // Check if the name is already in the teams array
                        if (!teams.includes(member.employee.name)) {
                            // If not included, push the name into the teams array
                            teams.push(member.employee.name);
                        }
                    }
                });
            });
            cachedValue = teams;
        }
    }

    teamLeaders.value = cachedValue.toString();
    return cachedValue.toString();
};

const getEmployeeValue = async () => {
    await EmployeeStore.getEmployeeTeamLeaders(+props.employee);

    let teams: string[] = [];
    const myTeam: any[] = EmployeeStore.myTeam;
    //@ts-ignore
    if (myTeam !== "") {
        myTeam.forEach((team) => {
            //@ts-ignore
            team.employeeTeams.forEach((member) => {
                if (member.team_role_id === 1) {
                    teams.push(member.employee.name);
                    // teams += member.employee.name + ", ";
                }
            });
        });

        teamLeaders.value = teams.toString();
        return teams.toString();
    }
};
</script>
<template>
    <div class="mt-3">
        <FormLabel class="form-label" for="crud-form-1"
            >Team Leaders<span style="color: rgb(255, 0, 0)"></span
        ></FormLabel>
        <FormInput
            id="team_leads"
            v-model="teamLeaders"
            :value="teamLeaders"
            class="form-control w-full"
            disabled
            name="team_leads"
            type="text"
        />
    </div>
</template>
