<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import Lucide from "../../../base-components/Lucide";
import {FormattedMenu} from "../side-menu";
import {linkTo} from "./mobile-menu";

interface MenuProps {
    menu: FormattedMenu;
    formattedMenuState: [
        (FormattedMenu | "divider")[],
        (computedFormattedMenu: Array<FormattedMenu | "divider">) => void
    ];
    level: "first" | "second" | "third";
    setActiveMobileMenu: (active: boolean) => void;
}

const router = useRouter();
const props = defineProps<MenuProps>();
const [formattedMenu, setFormattedMenu] = props.formattedMenuState;
</script>

<template>
    <a
        :class="[
      'h-[50px] flex items-center text-white',
      props.level == 'first' && 'px-6',
      props.level != 'first' && 'px-4',
    ]"
        :href="
      props.menu.subMenu
        ? '#'
        : ((pageName: string | undefined) => {
            try {
              return router.resolve({
                name: pageName,
              }).fullPath;
            } catch (err) {
              return '';
            }
          })(props.menu.pageName)
    "
        @click="
      (event) => {
        event.preventDefault();
        linkTo(props.menu, router, props.setActiveMobileMenu);
        setFormattedMenu([...formattedMenu]);
      }
    "
    >
        <div>
            <!-- Todo fix icon -->
            <!--      <Lucide :icon="props.menu.icon" />-->
        </div>
        <div class="flex items-center w-full ml-3">
            {{ props.menu.title }}
            <div
                v-if="props.menu.subMenu"
                :class="[
          'transition ease-in duration-100 ml-auto',
          props.menu.activeDropdown && 'transform rotate-180',
        ]"
            >
                <Lucide class="w-5 h-5" icon="ChevronDown"/>
            </div>
        </div>
    </a>
</template>
