<script lang="ts" setup>
import { useAuthStore } from "../../../stores/authStore";

const AuthStore = useAuthStore();
</script>
<template>
    <div class="report-box zoom-in">
        <div class="box p-5">
            <div class="flex">
                <div class="ml-auto"></div>
            </div>
            <div class="text-xl font-medium leading-8 mt-6">
                {{ AuthStore.myClient[0]?.legal_business_name }}
            </div>
            <div class="text-base text-slate-500 mt-1">Client Info</div>
        </div>
    </div>
</template>
