<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";
import { useEmployeesStore } from "../../stores/employeesStore";
import { useClientsStore } from "../../stores/clientsStore";

import router from "../../router";
import { useHelperStore } from "../../stores/helperStore";
import { useUiStore } from "../../stores/uiStore";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useTicketsStore } from "../../stores/ticketsStore";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { ClassicEditor } from "../../base-components/Ckeditor";
import { FormInput, FormLabel } from "../../base-components/Form";
import _ from "lodash";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import EmployeeInterface from "../interfaces/employeesInterface";
import { useShiftStore } from "../../stores/shiftStore";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();
const ClientsStore = useClientsStore();
const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const EmployeesStore = useEmployeesStore();
const ShiftStore = useShiftStore();

const loading = ref(false);
const ticketForm = reactive({
    dateFiled: "",
    employeeId: "",
    name: "",
    requestStatus: "Pending",
    type: "Request",
    shift: "",
    role: "",
    client: "",
    clientPoc: "",
    contact: "",
    personalEmail: "",
    team: "",
    wfh: "",
    pc: "",
    startDate: "",
    onboardingDate: "",
});

const rules = {
    //dateFiled: { required },
    //employeeId: { required },
    name: { required },
    requestStatus: { required },
    type: { required },
    role: { required },
    shift: {},
    client: { required },
    clientPoc: {},
    contact: { required },
    personalEmail: { required, email },
    team: { required },
    wfh: { required },
    pc: { required },
    startDate: { required },
    onboardingDate: { required },
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.name = "";
    ticketForm.requestStatus = "";
    ticketForm.type = "";
    ticketForm.role = "";
    ticketForm.client = "";
    ticketForm.contact = "";
    ticketForm.personalEmail = "";
    ticketForm.team = "";
    ticketForm.wfh = "";
    ticketForm.pc = "";
    ticketForm.startDate = "";
    ticketForm.onboardingDate = "";
};

onMounted(async () => {
    //if (window.Cypress) {
    //  window.FreshdeskStore = FreshdeskStore;
    // }
    await ClientsStore.getAllClients();
    await ShiftStore.getAllShifts({ dropdown: true });
    // await EmployeesStore.getAllEmployeesForTable();
    // populateEmployeeDetails();
});

const employee = reactive({
    data: null as EmployeeInterface | null,
});
const populateEmployeeDetails = () => {
    // console.log('populateEmployeeDetails', window.Laravel.user.id);
    // employee.data = EmployeesStore.get(parseInt(window.Laravel.user.id));
    console.log("AU", employee.data);
};

const submitTicket = async () => {
    loading.value = true;
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        console.error("missing data fields");
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
        loading.value = false;
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));
        console.log("ticketType", ticketType);
        const ticketFormData = new FormData();

        const form = new FormData();

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Name", ticketForm.name);
        desc_data.append("Role", ticketForm.role);
        desc_data.append("Shift", ticketForm.shift ?? "-");
        desc_data.append("Client", ticketForm.client);
        desc_data.append("Client POC", ticketForm.clientPoc ?? "-");
        desc_data.append("Contact", ticketForm.contact);
        desc_data.append("Personal Email", ticketForm.personalEmail);
        desc_data.append("Team", ticketForm.team);
        desc_data.append("WFO/WFH", ticketForm.wfh);
        desc_data.append("PC Set-UP", ticketForm.pc);
        desc_data.append("Start Date", ticketForm.startDate);
        desc_data.append("Onboarding Date", ticketForm.onboardingDate);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        form.append("id", String(id));
        form.append(
            "subject",
            // "Ticket for Log ins activation: " + AuthStore.authUser.name
            "Ticket for Log ins activation: " + ticketForm.name
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form)
            .then((response) => {
                console.log("tix response", response);
                if (response.status === "success") {
                    validate.value.$reset();
                    emit("output", { render: true, type: "success" });
                    if (id !== null) {
                        emit(
                            "ticketType",
                            HelperStore.getTicketType(parseInt(id))
                        );
                    }
                    showSuccessNotification();
                    formReset();
                    router.push({
                        name: "ticketDetails",
                        params: { id: response.data.id },
                    });
                } else {
                    console.log("response not success:", response);
                    emit("output", { render: true, type: "danger" });
                }
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const handleShift = () => {
    if (ticketForm.shift === "Select Shift") {
        ticketForm.shift = "";
    }
};

const handleWorkSetup = () => {
    if (ticketForm.wfh === "Select Work Setup") {
        ticketForm.wfh = "";
    }
};

const handleSelectClient = () => {
    const client = ClientsStore.clients.find(
        (a) => a.legal_business_name === ticketForm.client
    );

    ticketForm.clientPoc =
        client?.point_of_contacts?.map((a) => a.full_name).toString() ?? "";
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Name: <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="name"
                v-model="ticketForm.name"
                class="form-control w-full"
                name="name"
                placeholder=""
                type="text"
            />
            <template v-if="validate.name.$error">
                <div
                    v-for="(error, index) in validate.name.$errors"
                    id="name_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Role: <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="role"
                v-model="ticketForm.role"
                class="form-control w-full"
                name="role"
                placeholder=""
                type="text"
            />
            <template v-if="validate.role.$error">
                <div
                    v-for="(error, index) in validate.role.$errors"
                    id="role_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label">
                Client <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>

            <TomSelect
                id="client"
                v-model="ticketForm.client"
                class="w-full"
                name="client"
                @update:model-value="handleSelectClient"
            >
                <option>Select Client</option>
                <option
                    v-for="client of ClientsStore.clients"
                    :value="client.legal_business_name"
                >
                    {{ client.legal_business_name }}
                </option>
            </TomSelect>
        </div>
        <div class="mt-2">
            <template v-if="validate.client.$error">
                <div
                    v-for="(error, index) in validate.client.$errors"
                    id="client_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="clientPoc">
                Client POC
            </FormLabel>
            <FormInput
                id="clientPoc"
                v-model="ticketForm.clientPoc"
                :value="ticketForm.clientPoc"
                class="form-control w-full"
                name="clientPoc"
                placeholder=""
                type="text"
                disabled
            />
            <template v-if="validate.clientPoc.$error">
                <div
                    v-for="(error, index) in validate.clientPoc.$errors"
                    id="clientPoc_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Contact:
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="contact"
                v-model="ticketForm.contact"
                class="form-control w-full"
                name="contact"
                placeholder=""
                type="text"
            />
            <template v-if="validate.contact.$error">
                <div
                    v-for="(error, index) in validate.contact.$errors"
                    id="contact_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Personal Email:
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="personalEmail"
                v-model="ticketForm.personalEmail"
                class="form-control w-full"
                name="personalEmail"
                placeholder=""
                type="email"
            />
            <template v-if="validate.personalEmail.$error">
                <div
                    v-for="(error, index) in validate.personalEmail.$errors"
                    id="personalEmail_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Team: <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="team"
                v-model="ticketForm.team"
                class="form-control w-full"
                name="team"
                placeholder=""
                type="text"
            />
            <template v-if="validate.team.$error">
                <div
                    v-for="(error, index) in validate.team.$errors"
                    id="team_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-2"
                >Work Setup
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="wfh"
                v-model="ticketForm.wfh"
                class="w-full"
                name="wfh"
                @update:modelValue="handleWorkSetup()"
            >
                <option selected>Select Work Setup</option>
                <option value="Work from Home">Work from Home</option>
                <option value="Work from Office">Work from Office</option>
                <option value="Hybrid">Hybrid</option>
            </TomSelect>
        </div>
        
        <div class="mt-2">
            <template v-if="validate.wfh.$error">
                <div
                    v-for="(error, index) in validate.wfh.$errors"
                    id="wfh_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="shift"> Shift </FormLabel>

            <TomSelect
                id="shift"
                v-model="ticketForm.shift"
                class="w-full"
                name="shift"
                @update:model-value="handleShift()"
            >
                <option selected>Select Shift</option>
                <option
                    v-for="shift in ShiftStore.allShifts"
                    :value="shift.name"
                >
                    {{ shift.name }}
                </option>
            </TomSelect>
            <template v-if="validate.shift.$error">
                <div
                    v-for="(error, index) in validate.shift.$errors"
                    id="shift_error"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
                >PC Set-UP
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="pc"
                    v-model="ticketForm.pc"
                    :config="editorConfig"
                    name="pc"
                />
                <template v-if="validate.pc.$error">
                    <div
                        v-for="(error, index) in validate.pc.$errors"
                        id="pc_error"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Start Date:
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="startDate"
                v-model="ticketForm.startDate"
                :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="startDate"
            />
            <template v-if="validate.startDate.$error">
                <div
                    v-for="(error, index) in validate.startDate.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Onboarding Date:
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="onboardingDate"
                v-model="ticketForm.onboardingDate"
                :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="onboardingDate"
            />
            <template v-if="validate.onboardingDate.$error">
                <div
                    v-for="(error, index) in validate.onboardingDate.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-5 flex justify-end items-center">
            <router-link
                :disabled="loading"
                class="btn text-white bg-warning py-2 rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>

            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                :disabled="loading"
                @click="submitTicket"
            >
                <div v-if="loading" class="flex items-center">
                    <svg
                        aria-hidden="true"
                        class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        fill="none"
                        viewBox="0 0 100 101"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>

                    <div>Submitting ticket...</div>
                </div>

                <div v-else>Submit Ticket</div>
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
