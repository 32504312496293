import { defineStore } from "pinia";
import Icon from "../base-components/Lucide/Lucide.vue";
import { useAuthStore } from "./authStore";

export interface Menu {
    icon: typeof Icon | string;
    title: string;
    pageName?: string;
    subMenu?: Menu[];
    ignore?: boolean;
    enableCounter?: boolean;
    counterClass?: string;
    menuName?: string;
}

export interface SideMenuState {
    menu: Array<Menu | "divider">;
    ticketMenu: Array<Menu | "divider">;
}

export const useSideMenuStore = defineStore("sideMenu", {
    state: (): SideMenuState => ({
        menu: [],
        ticketMenu: [],
    }),
    actions: {
        processAvailableMenu() {
            const AuthStore = useAuthStore();
            const hrDasbhoard = [
                "admin",
                "payroll",
                "accounts receivable",
                "hr",
                "recruitment",
                "it",
            ];
            const devOnly = [
                "scruz@remoteemployee.com",
                "lallen@remoteemployee.com",
                "thaas@remoteemployee.com",
                "MKAlmadin@remoteemployee.com",
                "admin@test.com",
                "sam@test.com",
                "lucus@remote.com",
                "thaas@remote.com",
                "admin@testusers.com",
            ];
            const testerOnly = ["jsusi@remoteemployee.com", "jsusi@remote.com"];

            const adminOnly = ["admin"];
            const hrOnly = ["hr"];
            // @ts-ignore
            const roles = window.Laravel.role;
            // @ts-ignore
            const activatedPermissions = window.Laravel.permissions;

            let isHrDashboard = hrDasbhoard.includes(roles[0]);
            let isAdmin = adminOnly.includes(roles[0]);
            let isHr = hrOnly.includes(roles[0]);
            //@ts-ignore
            let isDev = devOnly.includes(window.Laravel.user.email);
            //@ts-ignore
            let isTester = testerOnly.includes(window.Laravel.user.email);

            //if (Object.values(AuthStore.activatedPermissionsName).includes('can view employees dashboard')) {
            if (!isHrDashboard) {
                this.menu.push({
                    icon: "Activity",
                    pageName: "dashboard",
                    title: "Dashboard",
                });
            }
            if (isHrDashboard) {
                this.menu.push({
                    icon: "Activity",
                    pageName: "dashboard",
                    title: "Dashboard",
                });
                if (isAdmin || isHr) {
                    this.menu.push({
                        icon: "Banknote",
                        pageName: "Payroll",
                        title: "Payroll",
                        subMenu: [
                            {
                                icon: "Settings",
                                pageName: "payrollConfigurations",
                                title: "Payroll Cutoff Config",
                                ignore: false,
                                enableCounter: false,
                                counterClass: "counter-payroll-configuration",
                                menuName: "payroll-configuration",
                            },
                        ],
                    });
                }
            }

            if ((isAdmin && isDev) || isTester) {
                this.menu.push({
                    icon: "DollarSign",
                    pageName: "invoicesDashboard",
                    title: "Invoices",
                    subMenu: [
                        {
                            icon: "LayoutDashboard",
                            pageName: "invoicesDashboard",
                            title: "Dashboard",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-invoices-dashboard",
                            menuName: "invoices-dashboard",
                        },
                        {
                            icon: "Newspaper",
                            pageName: "invoices",
                            title: "All Invoices",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-all-invoices",
                            menuName: "all-invoices",
                        },
                    ],
                });
            }

            this.menu.push({
                icon: "Clock",
                pageName: "myTimesheets",
                title: "My Timesheet",
            });

            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can view all clients"
                ).length >= 1
            ) {
                // if (Object.values(AuthStore.activatedPermissionsName).includes('can view all clients')) {
                this.menu.push({
                    icon: "Briefcase",
                    pageName: "clients-parent",
                    title: "Clients",
                    subMenu: [
                        {
                            icon: "Briefcase",
                            pageName: "clients",
                            title: "All Clients",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-clients",
                            menuName: "client",
                        },
                        {
                            icon: "Megaphone",
                            pageName: "clientNotifications",
                            title: "Notifications",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-clientnotifications",
                            menuName: "client",
                        },
                    ],
                    // enableCounter: false,
                    // counterClass: 'counter-clients'
                });
            }
            //if (Object.values(AuthStore.activatedPermissionsName).includes('can view all employees')) {
            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can view all employees"
                ).length >= 1
            ) {
                this.menu.push({
                    icon: "Users",
                    pageName: "employees",
                    title: "Employees",
                    subMenu: [
                        {
                            icon: "Users",
                            pageName: "employees",
                            title: "All Employees",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-clients",
                            menuName: "employee",
                        },
                        {
                            icon: "Clock",
                            pageName: "employeesAttendance",
                            title: "Attendance",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-clients",
                            menuName: "attendance",
                        },
                        {
                            icon: "Calendar",
                            pageName: "employeesShifts",
                            title: "Shifts",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "counter-employee-shifts",
                            menuName: "Shifts",
                        },
                    ],
                });
            }

            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can view employee schedule today"
                ).length >= 1
            ) {
                this.menu.push({
                    icon: "Calendar",
                    pageName: "employeesScheduleToday",
                    title: "Schedules Today",
                    enableCounter: false,
                    counterClass: "counter-schedules-today",
                    menuName: "Schedules Today",
                });
            }

            //if (Object.values(AuthStore.activatedPermissionsName).includes('can view all teams')) {
            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can view all teams"
                ).length >= 1
            ) {
                this.menu.push({
                    icon: "Cpu",
                    pageName: "teams",
                    title: "Teams",
                    enableCounter: false,
                    counterClass: "teams",
                });
            }
            this.menu.push({
                icon: "Cpu",
                pageName: "myTeam",
                title: "My Team",
                enableCounter: false,
                counterClass: "my-teams",
            });
            this.menu.push({
                icon: "Framer",
                pageName: "tickets-parent",
                title: "Tickets",
                subMenu: this.ticketMenu as {
                    icon: string;
                    pageName: string;
                    title: string;
                    menuName?: string;
                }[],
            });
            //if (Object.values(AuthStore.activatedPermissionsName).includes('can view all tickets')) {
            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can view all tickets"
                ).length >= 1
            ) {
                this.ticketMenu.push({
                    icon: "Trello",
                    pageName: "tickets",
                    title: "All Tickets",
                    ignore: false,
                    enableCounter: false,
                    counterClass: "all-tickets",
                });
            }
            // if (
            //     Object.values(AuthStore.activatedPermissionsName).includes('can approve all tickets') ||
            //     Object.values(AuthStore.activatedPermissionsName).includes('can approve assigned tickets')

            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can approve all tickets"
                ).length >= 1 ||
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can approve assigned tickets"
                ).length >= 1
            ) {
                this.ticketMenu.push({
                    icon: "ThumbsUp",
                    pageName: "ticketsForApproval",
                    title: "Tickets for Approval",
                    ignore: false,
                    enableCounter: true,
                    counterClass: "counter-tickets-for-approval",
                });
            }

            // if (Object.values(AuthStore.activatedPermissionsName).includes('can create ticket')) {
            if (
                activatedPermissions.filter(
                    (item: { name: string }) =>
                        item.name === "can create ticket"
                ).length >= 1
            ) {
                this.ticketMenu.push({
                    icon: "Award",
                    pageName: "myTickets",
                    title: "My Tickets",
                    ignore: false,
                    enableCounter: false,
                    counterClass: "counter-my-tickets",
                });
                this.ticketMenu.push({
                    icon: "Mail",
                    pageName: "submitATicket",
                    title: "Submit a Ticket",
                    ignore: false,
                    enableCounter: false,
                    counterClass: "counter-tickets",
                });
            }

            if (isAdmin || isHr) {
                const settingsSubMenu = [];
                if (isAdmin || isHr) {
                    settingsSubMenu.push({
                        icon: "Calendar",
                        pageName: "shifts",
                        title: "Shifts",
                        ignore: false,
                        enableCounter: false,
                        counterClass: "shifts",
                    });
                }
                if (isAdmin && isDev) {
                    settingsSubMenu.push(
                        {
                            icon: "Key",
                            pageName: "rolesAndPermissions",
                            title: "Roles and Permissions",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "roles-and-permissions",
                        },
                        {
                            icon: "Users",
                            pageName: "accountManagementView",
                            title: "User Accounts",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "account-management",
                        }
                    );
                }

                this.menu.push({
                    icon: "Settings",
                    pageName: "settings",
                    title: "Settings",
                    subMenu: settingsSubMenu,
                });
            }

            if (isAdmin || isHr) {
                this.menu.push({
                    icon: "History",
                    pageName: "activityLogs",
                    title: "Activity Logs",
                    subMenu: [
                        {
                            icon: "History",
                            pageName: "activityLogs",
                            title: "All Activity Logs",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "activity-logs",
                            menuName: "activityLog",
                        },
                        {
                            icon: "Calendar",
                            pageName: "shiftActivityLogs",
                            title: "Shift Activity Logs",
                            ignore: false,
                            enableCounter: false,
                            counterClass: "shift-activity-logs",
                            menuName: "shiftActivityLogs",
                        }
                    ],
                });
            }
        },
    },
});
