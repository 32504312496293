<script lang="ts" setup>
import { computed, defineProps } from "vue";

const props = defineProps({
    ring: {
        type: String,
        default: "",
    },
    size: {
        type: String,
        default: "",
    },
    name: {
        type: String,
        default: "Loading User",
    },
    employeeId: {
        type: Number,
        default: 0,
    },
});

const fontSize = computed(() => {
    if (props.size === "xs") {
        return "12px";
    }
    if (props.size === "sm") {
        return "20px";
    }
    return "50px";
});

const initials = computed(() => {
    if (typeof props.name === "undefined") {
        return "";
    }
    let fullName = props.name;
    let split = fullName.split(" ");
    let firstInitial = split[0].charAt(0).toUpperCase();
    let secondInitial = split[split.length - 1].charAt(0).toUpperCase();
    return firstInitial + secondInitial;
});
</script>
<template>
    <div
        :class="[
            ring === 'online'
                ? 'shadow-lg shadow-gray-500 border-4  border-green-500/75'
                : '',
            ring === 'offline'
                ? 'shadow-lg shadow-gray-500 border-4  border-red-500/75'
                : '',
            ring === 'no-border' ? 'border-2  border-custom-color-800/75' : '',
            size === 'sm' ? 'w-12 h-12' : '',
            size === 'xs' ? 'w-8 h-8' : '',
        ]"
        class="mt-1 ml-1 inline-flex overflow-hidden relative justify-center items-center bg-custom-color-1000 rounded-full dark:bg-custom-color-1000"
    >
        <span
            :style="{ 'font-size': fontSize }"
            class="text-custom-color-600 dark:text-custom-color-600"
        >
            {{ initials }}</span
        >
    </div>
</template>
