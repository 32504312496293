<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import { useColorSchemeStore } from "../../../../stores/color-scheme";
import { useDarkModeStore } from "../../../../stores/dark-mode";
import Chart from "../../../../base-components/Chart";
import { getColor } from "../../../../utils/colors";

const props = defineProps<{
    width?: number;
    height?: number;
    totalHoursRendered?: number;
    totalHoursRemaining?: number;
    totalBreaks?: any;
}>();

const colorScheme = computed(() => useColorSchemeStore().colorScheme);
const darkMode = computed(() => useDarkModeStore().darkMode);

const chartData = ref<number[]>([]);

onMounted(() => {
    // Set the initial value of chartData when the component is mounted
    chartData.value = [
        props.totalHoursRendered ? props.totalHoursRendered : 0,
        ...(props.totalBreaks || []),
        props.totalHoursRemaining ? props.totalHoursRemaining : 0,
    ];
});

// Watch for changes in props and update chartData
watch(
    () => [
        props.totalHoursRendered,
        props.totalBreaks,
        props.totalHoursRemaining,
    ],
    () => {
        chartData.value = [
            props.totalHoursRendered ? props.totalHoursRendered : 0,
            ...(props.totalBreaks || []),
            props.totalHoursRemaining ? props.totalHoursRemaining : 0,
        ];
    }
);

const chartColors = computed(() => {
    const colors = [];

    for (let i = 0; i < chartData.value.length; i++) {
        if (i === 0) {
            colors.push(getColor("customPrimary", 0.9));
        } else if (i < chartData.value.length - 1) {
            colors.push(getColor("warning", 0.9));
        } else {
            colors.push(getColor("light", 0.9));
        }
    }

    return colors;
});

const data = computed<ChartData>(() => {
    return {
        labels: [""],
        datasets: [
            {
                data: chartData.value,
                backgroundColor: colorScheme.value ? chartColors.value : "",
                hoverBackgroundColor: colorScheme.value
                    ? chartColors.value
                    : "",
                borderWidth: 5,
                borderColor: darkMode.value
                    ? getColor("darkmode.700")
                    : getColor("white"),
            },
        ],
    };
});

const options = computed<ChartOptions>(() => {
    return {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: "80%",
    };
});
</script>

<template>
    <Chart
        :data="data"
        :height="props.height"
        :options="options"
        :width="props.width"
        type="doughnut"
    />
</template>
