import { defineStore } from "pinia";
import Http from "../services/Http";
import { EmployeeSalaryHistoryInterface } from "../pages/interfaces/employeesInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";
export const useEmployeeSalaryHistoryStore = defineStore(
    "EmployeeSalaryHistoryStore",
    {
        state: (): {
            employeeSalaryHistories: EmployeeSalaryHistoryInterface[];
            employeeSalaryHistory: EmployeeSalaryHistoryInterface;
        } => {
            return {
                employeeSalaryHistories: [] as EmployeeSalaryHistoryInterface[],
                employeeSalaryHistory: {} as EmployeeSalaryHistoryInterface,
            };
        },

        actions: {
            async getEmployeeSalaryHistories(
                employeeId: number
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeSalaryHistoryInterface[]>
                >(`/employee-salary-histories?employee_id=${employeeId}`);

                this.employeeSalaryHistories = data;
            },

            async getEmployeeSalaryHistory(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeSalaryHistoryInterface>
                >(`/employee-salary-histories/${id}`);

                this.employeeSalaryHistory = data;
            },

            async addEmployeeSalaryHistory(
                payload: EmployeeSalaryHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<EmployeeSalaryHistoryInterface>
                >("/employee-salary-histories", payload);

                this.employeeSalaryHistories.unshift(data);
            },

            async updateEmployeeSalaryHistory(
                payload: EmployeeSalaryHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<EmployeeSalaryHistoryInterface>
                >(`/employee-salary-histories/${payload.id}`, payload);

                const index = this.employeeSalaryHistories.findIndex(
                    (item) => item.id === data.id
                );

                console.log(data);

                this.employeeSalaryHistories[index] = data;
            },

            async deleteEmployeeSalaryHistory(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(
                    `/employee-salary-histories/${id}`
                );

                const index = this.employeeSalaryHistories.findIndex(
                    (item) => item.id === id
                );

                this.employeeSalaryHistories.splice(index, 1);
            },
        },
    }
);
