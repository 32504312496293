import { defineStore } from "pinia";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import { useAuthStore } from "./authStore";
import ClientNotificationsInterface from "../pages/interfaces/clientNotificationInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

interface Temp {
    poc_id: number;
    recipients: string;
    subject: string;
    body: string;
    attachment: any[];
}

interface ClientNotificationsStoreState {
    notifications: ClientNotificationsInterface[];
    mode: string;
    attachment: any[];
    temp: {
        poc_id: number;
        recipients: string;
        subject: string;
        body: string;
        attachment: any[]; // Define attachment as a property of temp
    };
    poc_id: any[];
    s_poc_id: any[];
    $sentry: typeof Sentry | null;
}

export const useClientNotificationsStore = defineStore(
    "ClientNotificationsStore",
    {
        state: (): ClientNotificationsStoreState => {
            return {
                notifications: [],
                mode: "Add",
                attachment: [],
                temp: {} as Temp,
                poc_id: [],
                s_poc_id: [],
                $sentry: null,
            };
        },
        getters: {
            getMode(): string {
                return this.mode;
            },
        },
        actions: {
            async fill(params: any) {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<ClientNotificationsInterface[]>>(
                        "/client-notifications",
                        { params }
                    );
                    this.notifications = response.data;
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },
            addState() {
                this.mode = "Add";
            },
            updateState() {
                this.mode = "Update";
            },
            async save(formData: any) {
                try {
                    const AuthStore = useAuthStore();
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).post<ApiResponseInterface<any>>(
                        "/client-notifications",
                        formData,
                        AuthStore.token
                    );

                    return { status: response.status, data: response.data };
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },
            async saveTemp(formData: Temp) {
                this.temp = formData;
                //@ts-ignore
                //this.temp.attachment = this.attachment;
            },
            async uploadTemp(form: any) {
                try {
                    const AuthStore = useAuthStore();
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).post<ApiResponseInterface<any>>(
                        "/upload-client-notifications",
                        form,
                        AuthStore.token,
                        "multipart/form-data"
                    );

                    return { status: response.status, data: response.data };
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },
            async sendNotification() {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).post<ApiResponseInterface<any>>(
                        "/send-client-notifications",
                        this.temp
                    );

                    if (response.status == "success") {
                        this.temp = {
                            poc_id: 0,
                            recipients: "",
                            subject: "",
                            body: "",
                            attachment: [],
                        };
                        this.poc_id = [];
                        this.attachment = [];
                        this.s_poc_id = [];
                        console.log("notifications sent");
                    }
                    return response;
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            },

            async downloadAttachment(params: {
                file_name: string;
                client_notification_id: number;
            }) {
                try {
                    const AuthStore = useAuthStore();
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<any>>(
                        "/client-notification-attachments",
                        { params },
                        AuthStore.token,
                        true
                    );
                    // @ts-ignore
                    var fileURL = window.URL.createObjectURL(
                        //@ts-ignore
                        new Blob([response])
                    );
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", params.file_name);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (error) {
                    console.log(error);
                    return { status: "error" };
                }
            },
            updateTempPoc(id: any) {
                var idx = this.poc_id.indexOf(id);
                if (idx >= 0) {
                    this.poc_id.splice(idx, 1);
                } else {
                    this.poc_id.push(id);
                }
            },
        },
    }
);
