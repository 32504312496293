<template>
    <div>
        <div class="flex justify-end">
            <div class="w-1/4 h-6 bg-gray-200 rounded ml-auto"></div>
        </div>

        <div class="flex gap-4">
            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
        </div>

        <div class="flex gap-4">
            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
        </div>

        <div class="flex gap-4">
            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>

            <div class="h-4 bg-gray-200 rounded mt-5 w-2/4"></div>
        </div>

        <div class="flex justify-center">
            <div class="h-4 bg-gray-200 rounded mt-5 w-24"></div>
        </div>
    </div>
</template>
