<script lang="ts" setup>
import { FormInput } from "../../../base-components/Form";
import Button from "../../../base-components/Button";
import FormLabel from "../../../base-components/Form/FormLabel.vue";
import Lucide from "../../../base-components/Lucide";

const props = defineProps({
    errors: {
        type: Object,
        default: () => ({}),
    },
    overtimes: {
        type: Array as () => any[],
        default: () => [],
    },
    validate: {
        type: Object,
        default: () => ({}),
    },
});

const addShiftOvertime = () => {
    props.overtimes.push({
        start_time: "",
        end_time: "",
    });
};

const onClickTrash = (item: any) => {
    const index = props.overtimes.indexOf(item);

    if (props.overtimes.length > -1) {
        props.overtimes.splice(index, 1);
    }
};
</script>

<template>
    <div class="flex justify-between align-center">
        <h4 class="font-bold">Scheduled Overtime</h4>

        <Button type="button" @click="addShiftOvertime">Add</Button>
    </div>

    <div v-for="(item, index) in overtimes" :key="index">
        <div class="mt-4">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 lg:col-span-6">
                    <FormLabel class="form-label" for="name"
                        >Start Time
                    </FormLabel>
                    <FormInput
                        id="name"
                        v-model="item.start_time"
                        :value="item.start_time"
                        class="form-control w-full"
                        maxlength="50"
                        name="start_time"
                        placeholder=""
                        type="time"
                    />
                    <template
                        v-if="
                            props.errors[`shift_overtimes.${index}.start_time`]
                        "
                    >
                        <div
                            v-for="(error, errorIndex) in props.errors[
                                `shift_overtimes.${index}.start_time`
                            ]"
                            :key="errorIndex"
                            class="text-danger mt-2"
                        >
                            {{ error }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12 lg:col-span-5">
                    <FormLabel class="form-label" for="name"
                        >End Time
                    </FormLabel>
                    <FormInput
                        id="name"
                        v-model="item.end_time"
                        :value="item.end_time"
                        class="form-control w-full"
                        maxlength="50"
                        name="end_time"
                        placeholder=""
                        type="time"
                    />
                    <template
                        v-if="props.errors[`shift_overtimes.${index}.end_time`]"
                    >
                        <div
                            v-for="(error, errorIndex) in props.errors[
                                `shift_overtimes.${index}.end_time`
                            ]"
                            :key="errorIndex"
                            class="text-danger mt-2"
                        >
                            {{ error }}
                        </div>
                    </template>
                </div>
                <div class="col-span-12 lg:col-span-1 flex items-end mb-3">
                    <Lucide
                        class="w-4 h-4 text-red-500 hover:text-red-800 cursor-pointer"
                        icon="Trash"
                        @click="onClickTrash(item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
