<script lang="ts" setup>
import { FormInput } from "../../base-components/Form";
import { TransitionRoot } from "@headlessui/vue";
import { computed, ref } from "vue";
import Lucide from "../../base-components/Lucide";
import { useGlobalSearchStore } from "../../stores/globalSearchStore";
import CustomIcon from "../Icons/CustomIcon.vue";
import { debounce } from "lodash";

let loading = ref(false);

interface SearchResult {
    title: string;
    id: number;
    searchable: {
        id: number;
    };
}

interface GroupedResult {
    [key: string]: SearchResult[];
}

const GlobalSearchStore = useGlobalSearchStore();
const searchDropdown = ref(false);

const keyword = ref("");
const hideSearchDropdown = () => {
    const inputElement = document.querySelector(
        "#globalSearchInput"
    ) as HTMLInputElement;
    if (inputElement) {
        inputElement.value = "";
    }
    keyword.value = "";
    searchDropdown.value = false;
    GlobalSearchStore.globalSearchResults = [];
};

const showSearchDropdown = () => {
    searchDropdown.value = true;
};

const keywordChanged = debounce(async (e: any) => {
    console.log("keywordChanged");
    console.log(keyword.value);

    loading.value = true;

    if (keyword.value.length === 0 || keyword.value === "") {
        GlobalSearchStore.globalSearchResults = [];
        hideSearchDropdown();
        loading.value = false;
        return;
    }

    if (keyword.value.length < 3) {
        loading.value = false;
        return;
    }

    try {
        await GlobalSearchStore.getGlobalSearchResults(keyword.value);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        showSearchDropdown();
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
}, 500);

const groupedResults = computed<GroupedResult>(() => {
    //console.clear();
    //console.log("groupedResults");
    const grouped = {};

    const searchResults = GlobalSearchStore.globalSearchResults;
    //console.log(searchResults);
    searchResults?.forEach((result) => {
        const type = result.type;
        //@ts-ignore
        if (!grouped[type]) {
            //@ts-ignore
            grouped[type] = [];
        }
        // @ts-ignore
        grouped[type].push({ title: result.title, id: result.searchable.id });
    });

    return grouped;
});

const capitalizeFirstWord = (str: any) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const highlightedTitle = (title: any, keyword: any) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    const highlightedText = title.replace(regex, "<b>$1</b>");
    return highlightedText;
};
</script>
<template>
    <div class="relative mr-3 intro-x sm:mr-6">
        <div class="relative hidden sm:block">
            <FormInput
                id="globalSearchInput"
                v-model="keyword"
                :value="keyword"
                class="border-transparent w-56 shadow-none rounded-full bg-slate-200 pr-8 transition-[width] duration-300 ease-in-out focus:border-transparent focus:w-72 dark:bg-darkmode-400"
                placeholder="Search Emp. / Client"
                type="text"
                @update:model-value="keywordChanged"
            />
            <Lucide
                v-if="!loading && Object.keys(groupedResults).length === 0"
                class="absolute inset-y-0 right-0 w-5 h-5 my-auto mr-3 text-slate-600 dark:text-slate-500"
                icon="Search"
            />
            <div
                v-if="!loading && Object.keys(groupedResults).length !== 0"
                class="absolute inset-y-0 right-0 w-5 h-5 my-auto mr-3 text-slate-600 dark:text-slate-500 cursor-pointer"
                @click="hideSearchDropdown"
            >
                <svg
                    class="icon icon-tabler icon-tabler-x"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                </svg>
            </div>
            <div
                v-if="loading"
                class="absolute inset-y-0 right-0 w-5 h-5 my-auto mr-3 text-slate-600 dark:text-slate-500"
                role="status"
            >
                <svg
                    aria-hidden="true"
                    class="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    fill="none"
                    viewBox="0 0 100 101"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <a class="relative text-white/70 sm:hidden" href="">
            <Lucide class="w-5 h-5 dark:text-slate-500" icon="Search" />
        </a>
        <TransitionRoot
            :show="searchDropdown"
            as="template"
            enter="transition-all ease-linear duration-150"
            enterFrom="mt-5 invisible opacity-0 translate-y-1"
            enterTo="mt-[3px] visible opacity-100 translate-y-0"
            entered="mt-[3px]"
            leave="transition-all ease-linear duration-150"
            leaveFrom="mt-[3px] visible opacity-100 translate-y-0"
            leaveTo="mt-5 invisible opacity-0 translate-y-1"
        >
            <div
                :class="{
                    'max-h-[300px] overflow-y-auto':
                        Object.keys(groupedResults).length !== 0,
                    'h-[20px]': Object.keys(groupedResults).length === 0,
                }"
                class="absolute right-0 z-10 mt-[3px] border border-gray-200 rounded-xl shadow-lg"
            >
                <div class="w-[450px] py-5 px-3 box">
                    <div
                        v-if="Object.keys(groupedResults).length === 0"
                        class=""
                    >
                        No results found
                    </div>
                    <div v-else>
                        <div
                            v-for="(group, groupName) in groupedResults"
                            :key="groupName"
                        >
                            <div class="mb-2 font-medium text-md pb-2 flex">
                                <div>
                                    <CustomIcon
                                        v-if="groupName === 'clients'"
                                        :height="18"
                                        :icon="'Building'"
                                        :width="18"
                                    />
                                    <CustomIcon
                                        v-else
                                        :height="18"
                                        :icon="'Users'"
                                        :width="18"
                                    />
                                </div>
                                <div>
                                    {{ capitalizeFirstWord(groupName) }}
                                </div>
                            </div>
                            <div
                                v-for="item in group"
                                :key="item.title"
                                class="mb-2 border border-gray-200 rounded-xl pb-2 hover:bg-custom-color-1000/20"
                            >
                                <router-link
                                    :to="{
                                        name:
                                            groupName === 'clients'
                                                ? 'clientsDetails'
                                                : 'employeesDetails',
                                        params: { id: item.id }, //@ts-ignore
                                    }"
                                    class="flex items-center mt-2"
                                    @click="hideSearchDropdown"
                                >
                                    <div
                                        class="ml-6"
                                        v-html="
                                            highlightedTitle(
                                                item.title,
                                                keyword
                                            )
                                        "
                                    ></div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionRoot>
    </div>
</template>
