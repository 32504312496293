import { defineStore } from "pinia";
import { InvoiceNoteInterface } from "../pages/interfaces/invoiceInterface";
import Http from "../services/Http";

export const useInvoiceNoteStore = defineStore("InvoiceNoteStore", {
    state: (): {
        notes: InvoiceNoteInterface[];
        note: InvoiceNoteInterface;
    } => {
        return {
            notes: [] as InvoiceNoteInterface[],
            note: {} as InvoiceNoteInterface,
        };
    },

    actions: {
        async getInvoiceNotes(invoice_id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            let url = "/invoice-notes";

            if (invoice_id) {
                url += `?invoice_id=${invoice_id}`;
            }

            this.notes = await new Http(baseUrl).get<InvoiceNoteInterface[]>(
                `/invoice-notes?invoice_id=${invoice_id}`
            );
        },

        async addNote(payload: InvoiceNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).post<InvoiceNoteInterface>(
                "/invoice-notes",
                payload
            );

            this.notes.push(data);
        },

        async updateNote(payload: InvoiceNoteInterface) {
            const baseUrl = import.meta.env.VITE_API_URL;

            const data = await new Http(baseUrl).put<InvoiceNoteInterface>(
                `/invoice-notes/${payload.id}`,
                payload
            );

            const index = this.notes.findIndex(
                (a: InvoiceNoteInterface) => a.id === data.id
            );

            Object.assign(this.notes[index], data);
        },

        async deleteNote(id: number) {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete<void>(`/invoice-notes/${id}`);

            const index = this.notes.findIndex(
                (a: InvoiceNoteInterface) => a.id === id
            );

            this.notes.splice(index, 1);
        },
    },
});
