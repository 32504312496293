import {Router} from "vue-router";
import {slideDown, slideUp} from "../../../utils/helper";
import {FormattedMenu} from "../side-menu";

const linkTo = (
    menu: FormattedMenu,
    router: Router,
    setActiveMobileMenu: (active: boolean) => void
) => {
    if (menu.subMenu) {
        menu.activeDropdown = !menu.activeDropdown;
    } else {
        if (menu.pageName !== undefined) {
            setActiveMobileMenu(false);
            router.push({
                name: menu.pageName,
            });
        }
    }
};

const enter = (el: HTMLElement) => {
    slideDown(el, 300);
};

const leave = (el: HTMLElement) => {
    slideUp(el, 300);
};

export {linkTo, enter, leave};
