<script setup lang="ts">
import { ref, defineProps, PropType, computed } from "vue";
import Modal from "../Modal.vue";
import Button from "../../../base-components/Button";
import Lucide from "../../../base-components/Lucide";
import ClientInterface from "../../interfaces/clientsInterface";

const { client } = defineProps({
    client: {
        type: Object as PropType<ClientInterface>,
        required: true,
    },
});

const incompleteConfigurations = computed(() => {
    const configurations = [
        {
            name: "Client Invoice Configuration should be configured",
            status: client.client_invoice_configs?.length === 0,
        },
    ];

    const data: string[] = [];
    configurations.forEach((config) => {
        console.log(config.name, config.status);
        if (config.status && !data.includes(config.name)) {
            data.push(config.name);
        }
    });

    return data;
});

const dialog = ref(false);
</script>

<template>
    <Modal :open="dialog" size="lg">
        <template #trigger>
            <div class="flex justify-center">
                <Lucide
                    icon="AlertTriangle"
                    class="h-4 w-4 text-yellow-500 cursor-pointer"
                    v-if="
                        client.id === 1
                            ? false
                            : client.client_invoice_configs?.length === 0
                    "
                    @click="dialog = true"
                />
            </div>
        </template>

        <template #content>
            <div class="flex flex-row items-start">
                <div class="bg-orange-50 p-2 rounded-full">
                    <Lucide
                        icon="AlertTriangle"
                        class="h-6 w-6 text-orange-500"
                    />
                </div>

                <div class="ml-6">
                    <span class="font-medium">
                        Please be informed that the the following needs to be
                        configured:
                    </span>

                    <ul class="mt-4 list-disc list-inside">
                        <li
                            v-for="(
                                configuration, index
                            ) in incompleteConfigurations"
                            :key="index"
                        >
                            {{ configuration }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template #action>
            <div class="flex justify-end">
                <div>
                    <Button
                        class="w-24 mr-1"
                        type="button"
                        variant="outline-secondary"
                        @click="dialog = false"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </template>
    </Modal>
</template>
