<script lang="ts" setup>
import { computed, defineProps, onMounted } from "vue";
import Avatar from "./Avatar.vue";
import { isset } from "../../utils/helper";
import CustomIcon from "../Icons/CustomIcon.vue";

interface TeamMember {
    id: number;
    name: string;
    position: string;
    team: string;
    // add more properties if necessary
}

const props = defineProps({
    data: Object,
    name: String,
});

onMounted(() => {});

const sortedTeams = computed(() => {
    const groupedTeams: Record<string, TeamMember[]> = {};

    for (const teamMember of props.data as TeamMember[]) {
        if (!groupedTeams[teamMember.team]) {
            groupedTeams[teamMember.team] = [];
        }
        groupedTeams[teamMember.team].push(teamMember);
    }

    return groupedTeams;
});
</script>
<template>
    <div class="intro-y box col-span-12 border-2">
        <div
            class="flex items-center px-5 py-5 sm:py-5 border-b border-slate-200/60 dark:border-darkmode-400"
        >
            <h2 id="client-teams" class="font-medium text-base mr-auto">
                {{ props.name }}
            </h2>
        </div>

        <div v-if="isset(sortedTeams)">
            <div
                v-for="(teamMembers, teamName) in sortedTeams"
                :key="teamName"
                class="ml-5 col-span-6 intro-y md:col-span-6 px-2 py-4"
            >
                <div class="text-xl text-center lg:text-left mx-2 mb-5 mt-10">
                    {{ teamName }}
                </div>
                <div class="grid grid-cols-4 gap-4 mt-2">
                    <div
                        v-for="teamMember in teamMembers"
                        :key="teamMember.name"
                        class="flex flex-col items-center p-5 lg:flex-row shadow-lg rounded"
                    >
                        <div
                            class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1"
                        >
                            <Avatar
                                :name="teamMember.name"
                                :ring="'online'"
                                :size="'sm'"
                            />
                        </div>
                        <div
                            class="mt-3 text-center lg:ml-2 lg:mr-auto lg:text-left lg:mt-0"
                        >
                            <router-link
                                :to="{
                                    name: 'employeesDetails',
                                    params: { id: teamMember.id },
                                }"
                                class="font-medium"
                            >
                                {{ teamMember.name }}
                            </router-link>
                            <div class="text-slate-500 text-xs mt-0.5">
                                {{ teamMember.position }}
                            </div>
                        </div>
                        <div class="flex mt-4 lg:mt-0">
                            <!--                        <Button class="px-2 py-1" variant="outline-secondary">Profile</Button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="p-5">
            <div class="text-center">
                <CustomIcon :height="100" :icon="'UserCircle'" :width="100" />
                <div class="text-gray-600 mt-2">No teams available</div>
            </div>
        </div>
    </div>
</template>
