<script lang="ts" setup>
import CustomIcon from "../../Icons/CustomIcon.vue";
import { useClockInsStore } from "../../../stores/clockInsStore";
import { onMounted } from "vue";

const ClockInsStore = useClockInsStore();

onMounted(async () => {
    await getTotalClockOutToday();
    (window as any).Echo.channel("clockin-channel").listen(
        "ClockIn",
        async (e: any) => {
            await getTotalClockOutToday();
        }
    );
});

const getTotalClockOutToday = async () => {
    await ClockInsStore.getTotalClockOutForToday();
};
</script>
<template>
    <div class="report-box zoom-in">
        <div class="box p-5">
            <div class="flex">
                <CustomIcon :height="28" :icon="'Clock'" :width="28" />
                <div class="ml-auto"></div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">
                {{ ClockInsStore.clockOutToday }}
            </div>
            <div class="text-md text-slate-500 mt-1">Clock Outs</div>
        </div>
    </div>
</template>
