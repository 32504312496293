import { defineStore } from "pinia";
import _ from "lodash";

interface UiStoreState {
    breadcrumb: string;
}

export const useUiStore = defineStore("UiStore", {
    state: (): UiStoreState => {
        return {
            breadcrumb: "",
        };
    },
    actions: {
        setBreadcrumb($text: string) {
            if (typeof $text !== "undefined") {
                if ($text === "tickets") {
                    this.breadcrumb = "All Tickets";
                } else if ($text === "submitATicket") {
                    this.breadcrumb = "Ticket > Submit Ticket";
                } else {
                    // Split camelCase text into separate words
                    const words = $text
                        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                        .split(" ");

                    // Capitalize the first letter of each word (except 'a')
                    const capitalizedWords = words.map((word) => {
                        return word !== "a"
                            ? word.charAt(0).toUpperCase() + word.slice(1)
                            : "a";
                    });

                    // Join the words back together with a space
                    const formattedText = capitalizedWords.join(" ");

                    this.breadcrumb = formattedText;
                }
            }
        },
        capitalizeFirstLetter(string: string | undefined | null): string {
            if (_.isNil(string)) {
                return "";
            }

            const w = string.replaceAll("-", " ");
            const words = w.split(" ");
            for (let i = 0; i < words.length; i++) {
                if (!_.isNil(words[i][0]))
                    // getting an error if [0] = undefined
                    words[i] =
                        words[i][0].toUpperCase() + words[i].substring(1);
            }

            return words.join(" ");
        },
    },
    getters: {
        getBreadcrumb(): string {
            return this.breadcrumb;
        },
    },
});
