<script lang="ts" setup>
import Lucide from "../base-components/Lucide";
import { Menu, Tab } from "../base-components/Headless";
import Tippy from "../base-components/Tippy";
import ReportBarChart1 from "../components/ReportBarChart1";
import ReportDonutChart2 from "../components/ReportDonutChart2";
import Button from "../base-components/Button";
import Avatar from "./components/Avatar.vue";
import { useInvoiceStore } from "../stores/invoiceStore";
import { onMounted } from "vue";

const InvoiceStore = useInvoiceStore();

onMounted(async () => {
    await InvoiceStore.getInvoiceStats();
    console.log(InvoiceStore.stats);
});
</script>
<template>
    <div class="relative">
        <div class="grid grid-cols-12 gap-6">
            <div class="z-20 col-span-12 xl:col-span-9 2xl:col-span-9 bg-white">
                <div class="grid grid-cols-12 mb-3 mt-14 sm:gap-10 intro-y">
                    <div
                        class="relative col-span-12 py-6 text-center sm:col-span-6 md:col-span-4 sm:pl-5 md:pl-0 lg:pl-5 sm:text-left"
                    >
                        <Menu
                            class="absolute pt-0.5 2xl:pt-0 mt-5 2xl:mt-6 top-0 right-0"
                        >
                            <Menu.Button as="a" class="block" href="#">
                                <Lucide
                                    class="w-5 h-5 text-slate-500"
                                    icon="MoreVertical"
                                />
                            </Menu.Button>
                            <Menu.Items class="w-40">
                                <Menu.Item>
                                    <Lucide
                                        class="w-4 h-4 mr-2"
                                        icon="FileText"
                                    />
                                    Monthly Report
                                </Menu.Item>
                                <Menu.Item>
                                    <Lucide
                                        class="w-4 h-4 mr-2"
                                        icon="FileText"
                                    />
                                    Annual Report
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                        <div class="-mb-1 text-sm font-medium 2xl:text-base">
                            <span
                                class="font-normal text-slate-600 dark:text-slate-300"
                            >
                                General Overview
                            </span>
                        </div>
                        <div
                            class="flex items-center justify-center text-base leading-3 2xl:text-lg sm:justify-start text-slate-600 dark:text-slate-300 mt-14 2xl:mt-24"
                        >
                            Total Revenue for 2023
                            <Tippy
                                as="div"
                                content="Total value of your sales: $158.409.416"
                            >
                                <Lucide
                                    class="w-5 h-5 ml-1.5 mt-0.5"
                                    icon="AlertCircle"
                                />
                            </Tippy>
                        </div>
                        <div class="mt-5 mb-3 2xl:flex">
                            <div
                                class="flex items-center justify-center sm:justify-start"
                            >
                                <div
                                    class="relative pl-3 text-2xl font-medium leading-6 2xl:text-3xl 2xl:pl-4"
                                >
                                    <span
                                        class="absolute top-0 left-0 -mt-1 text-xl 2xl:text-2xl 2xl:mt-0"
                                    >
                                        $
                                    </span>
                                    142,402,210
                                </div>
                                <a
                                    class="ml-4 text-slate-500 2xl:ml-16"
                                    href=""
                                >
                                    <Lucide class="w-4 h-4" icon="RefreshCcw" />
                                </a>
                            </div>
                            <div
                                class="mt-5 2xl:flex 2xl:justify-center 2xl:mt-0 2xl:-ml-20 2xl:w-14 2xl:flex-none 2xl:pl-2.5"
                            >
                                <Tippy
                                    as="div"
                                    class="inline-flex items-center px-2 py-1 text-xs font-medium text-white rounded-full cursor-pointer bg-success 2xl:text-sm 2xl:p-0 2xl:text-success 2xl:bg-transparent 2xl:flex 2xl:justify-center"
                                    content="49% Higher than last month"
                                >
                                    49%
                                    <Lucide
                                        class="w-4 h-4 ml-0.5"
                                        icon="ChevronUp"
                                    />
                                </Tippy>
                            </div>
                        </div>
                        <div class="text-slate-500">
                            Last updated 1 hour ago
                        </div>
                        <div
                            class="mt-6 -mb-1 2xl:text-base text-slate-600 dark:text-slate-300"
                        >
                            Total Pending Invoices
                            <a
                                class="underline decoration-dotted underline-offset-4 text-primary dark:text-slate-400"
                                href=""
                            >
                                $12,921,050
                            </a>
                        </div>
                        <Menu class="mt-14 2xl:mt-24 w-44 2xl:w-52">
                            <Menu.Button
                                :as="Button"
                                class="relative justify-start w-full px-4"
                                rounded
                                variant="primary"
                            >
                                Download Reports
                                <span
                                    class="absolute top-0 bottom-0 right-0 flex items-center justify-center w-8 h-8 my-auto ml-auto mr-1"
                                >
                                    <Lucide
                                        class="w-4 h-4"
                                        icon="ChevronDown"
                                    />
                                </span>
                            </Menu.Button>
                            <Menu.Items
                                class="w-44 2xl:w-52"
                                placement="bottom-start"
                            >
                                <Menu.Item>
                                    <Lucide
                                        class="w-4 h-4 mr-2"
                                        icon="FileText"
                                    />
                                    Monthly Report
                                </Menu.Item>
                                <Menu.Item>
                                    <Lucide
                                        class="w-4 h-4 mr-2"
                                        icon="FileText"
                                    />
                                    Annual Report
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </div>
                    <div
                        class="col-span-12 row-start-2 px-10 py-6 -mx-5 border-t border-black border-dashed md:row-start-auto md:col-span-4 border-opacity-10 md:border-t-0 md:border-l md:border-r sm:px-28 md:px-5"
                    >
                        <div class="flex flex-wrap items-center">
                            <div
                                class="flex items-center justify-center w-full mb-5 mr-auto sm:w-auto sm:justify-start 2xl:mb-0"
                            >
                                <div
                                    class="w-2 h-2 -mt-4 rounded-full bg-primary"
                                ></div>
                                <div class="ml-3.5">
                                    <div
                                        class="relative text-xl 2xl:text-2xl font-medium leading-6 2xl:leading-5 pl-3.5 2xl:pl-4"
                                    >
                                        <span
                                            class="absolute text-base 2xl:text-xl top-0 left-0 2xl:-mt-1.5"
                                        >
                                            $
                                        </span>
                                        47,578.77
                                    </div>
                                    <div class="mt-2 text-slate-500">
                                        Average Monthly Income
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-10 text-slate-600 dark:text-slate-300">
                            Monthly Income/Expense
                        </div>
                        <div class="mt-6">
                            <ReportBarChart1 :height="290" />
                        </div>
                    </div>
                    <Tab.Group
                        class="col-span-12 py-6 pl-4 -ml-4 border-t border-l border-black border-dashed sm:col-span-6 md:col-span-4 border-opacity-10 sm:border-t-0 md:border-l-0 md:ml-0 md:pl-0"
                    >
                        <div class="relative mt-8">
                            <ReportDonutChart2
                                :height="215"
                                :chart-data="[
                                    InvoiceStore.stats.paid_count,
                                    InvoiceStore.stats.overdue_count,
                                    InvoiceStore.stats.sent_count,
                                ]"
                            />
                            <div
                                class="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full"
                            >
                                <div class="text-xl font-medium 2xl:text-2xl">
                                    {{ InvoiceStore.stats.invoices_count }}
                                </div>
                                <div class="text-slate-500 mt-0.5">
                                    Invoices
                                </div>
                            </div>
                        </div>
                        <div class="w-10/12 mx-auto mt-8 2xl:w-2/3">
                            <div class="flex items-center">
                                <div
                                    class="w-2 h-2 mr-3 rounded-full bg-primary"
                                ></div>
                                <span class="truncate">Invoice Sent</span>
                                <span class="font-medium xl:ml-auto">
                                    {{ InvoiceStore.stats.sent_count }}
                                </span>
                            </div>
                            <div class="flex items-center mt-4">
                                <div
                                    class="w-2 h-2 mr-3 rounded-full bg-pending"
                                ></div>
                                <span class="truncate">Paid Invoices</span>
                                <span class="font-medium xl:ml-auto">
                                    {{ InvoiceStore.stats.paid_count }}
                                </span>
                            </div>
                            <div class="flex items-center mt-4">
                                <div
                                    class="w-2 h-2 mr-3 rounded-full bg-warning"
                                ></div>
                                <span class="truncate">Overdue</span>
                                <span class="font-medium xl:ml-auto">
                                    {{ InvoiceStore.stats.overdue_count }}
                                </span>
                            </div>
                        </div>
                    </Tab.Group>
                </div>
            </div>
            <div
                class="z-20 col-span-12 xl:col-span-3 2xl:col-span-3 bg-white p-2 rounded"
            >
                <div class="col-span-12 mt-3 md:col-span-6 2xl:col-span-12">
                    <div class="flex items-center h-10 intro-x">
                        <h2 class="mr-5 text-lg font-medium truncate">
                            Transactions
                        </h2>
                    </div>
                    <div class="mt-5">
                        <div class="intro-x">
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                            <div
                                class="flex items-center px-5 py-3 mb-3 box zoom-in"
                            >
                                <Avatar
                                    :name="'Angelina Jolie'"
                                    :ring="'online'"
                                    :size="'sm'"
                                />

                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">Acumen</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        23 March 2021
                                    </div>
                                </div>
                                <div class="text-success">$100.00</div>
                            </div>
                        </div>
                        <a
                            class="block w-full py-3 text-center border border-dotted rounded-md intro-x border-slate-400 dark:border-darkmode-300 text-slate-500"
                            href=""
                        >
                            View More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
