<script lang="ts" setup>
import { defineProps, onMounted, ref } from "vue";
import { useHelperStore } from "../../../stores/helperStore";

const HelperStore = useHelperStore();

const props = defineProps({
    timezone: {
        type: String,
        default: "Asia/Manila",
    },
});

const currentTime = ref("");

onMounted(async () => {
    await HelperStore.getServerTime();
    currentTime.value = "loading...";
    //currentTime.value = HelperStore.serverTime;
    startClock();
});

const startClock = () => {
    const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Manila",
    };
    //@ts-ignore
    const formatter = new Intl.DateTimeFormat([], options);

    setInterval(() => {
        const date = new Date();
        const asiaManilaTime = formatter.format(date);
        currentTime.value = adjustMidnightHour(asiaManilaTime);
    }, 1000);
};

const adjustMidnightHour = (timeString: any) => {
    if (timeString.startsWith("24")) {
        return "00" + timeString.slice(2);
    }
    return timeString;
};
</script>

<template>
    <div
        class="absolute -top-0 left-0 flex flex-col items-center justify-center w-full h-full"
        style="top: -26px !important"
    >
        <div class="text-2xl font-medium">{{ currentTime }}</div>
        <div class="text-slate-500 mt-0.5">Current Time</div>
    </div>
</template>
