import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import Http from "../services/Http";
import * as Sentry from "@sentry/browser";
import ClientInterface, {
    ClientTableDataInterface,
} from "../pages/interfaces/clientsInterface";
import ApiResponseInterface from "../pages/interfaces/apiResponseInterface";

interface ClientsStoreState {
    clientsTableData: ClientTableDataInterface;
    mode: string;
    formData: any[];
    teams: any[];
    clients: ClientInterface[];
    clientsForDropdown: any[];
    $sentry: typeof Sentry | null;
    clientDetails: ClientInterface;
}

export const useClientsStore = defineStore("ClientsStore", {
    state: (): ClientsStoreState => {
        return {
            clientsTableData: { data: [], total: 0 },
            mode: "Add",
            formData: [],
            teams: [],
            clients: [],
            clientsForDropdown: [],
            $sentry: null,
            clientDetails: {} as ClientInterface,
        };
    },
    getters: {
        getMode(): string {
            return this.mode;
        },
    },
    actions: {
        async getAllClientsForTable(params: any): Promise<void> {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<ClientInterface[]>>(
                    "/get-all-clients-for-table",
                    { params }
                );
                this.clientsTableData = {
                    data: response.data,
                    total: response.total ?? 0,
                };
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getClientsForDropdown() {
            console.log("getClientsForDropdown");
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>("/get-clients-for-dropdown");
                this.clientsForDropdown = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getClientTeam(id: number) {
            try {
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(`/get-client-teams/${id}`);
                return response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
        },
        async getAllClients() {
            console.log("getAllClients");
            const AuthStore = useAuthStore();
            if (
                AuthStore.activatedPermissionsName.includes(
                    "can view all clients"
                )
            ) {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<any>>("/get-all-clients");
                    console.log(response.data);
                    this.clients = response.data;
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            } else {
                console.log("no permission to view all clients");
            }
        },
        async getClientDetails(id: number) {
            const AuthStore = useAuthStore();
            if (
                AuthStore.activatedPermissionsName.includes(
                    "can view all clients"
                )
            ) {
                try {
                    const response = await new Http(
                        import.meta.env.VITE_API_URL
                    ).get<ApiResponseInterface<any>>(
                        `/get-client-details/${id}`
                    );

                    this.clientDetails = response.data[0];

                    return response.data[0];
                } catch (error) {
                    if (this.$sentry) {
                        (this.$sentry as typeof Sentry).captureException(error);
                    }
                    console.log(error);
                }
            } else {
                console.log("no permission to view all clients");
            }
        },
        async save(formData: ClientInterface) {
            console.log("save", formData);
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>(
                    "/clients",
                    formData,
                    AuthStore.token
                );
                return { status: response.status, data: response.data };
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async update(formData: ClientInterface) {
            console.log("update api call", formData);
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).put<ApiResponseInterface<any>>(
                    "/clients",
                    formData,
                    AuthStore.token
                );
                return { status: response.status, data: response.data };
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }

                throw error;
            }
        },
        async delete(id: number) {
            console.log("delete api call", id);
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).delete<ApiResponseInterface<any>>(
                    `/clients`,
                    { id: id },
                    AuthStore.token
                );
                return { status: response.status, data: response.data };
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        async uploadAttachment(formData: ClientInterface) {
            let stats = "";
            let files = "";
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).post<ApiResponseInterface<any>>(
                    "/client-attachments",
                    formData,
                    AuthStore.token,
                    "multipart/form-data"
                );
                stats = response.status;
                files = response.data;
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
            }
            return {
                status: stats,
                data: files,
            };
        },
        async downloadAttachment(params: {
            reference_id?: any;
            file_name: any;
        }) {
            try {
                const AuthStore = useAuthStore();
                const response = await new Http(
                    import.meta.env.VITE_API_URL
                ).get<ApiResponseInterface<any>>(
                    "/client-attachments",
                    { params },
                    AuthStore.token,
                    true
                );
                console.log(response);
                // @ts-ignore
                var fileURL = window.URL.createObjectURL(new Blob([response]));
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", params.file_name);
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (error) {
                if (this.$sentry) {
                    (this.$sentry as typeof Sentry).captureException(error);
                }
                console.log(error);
                return { status: "error" };
            }
        },
        addState() {
            this.mode = "Add";
        },
        updateState() {
            this.mode = "Update";
        },
        setTeams(data: any[]) {
            this.teams = data;
        },
    },
});
